<template>
    <div class="lt">
<!--        <img src="../assets/ysqd.png" alt="">-->
        <a href="#/homepage">云烟</a>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Navbartext"
}
</script>

<style scoped>
.lt {
    float: left;
    width: 150px;
    height: 70px;
    margin:0 20px;
    font-size: 20px;
    background-image: linear-gradient(-135deg, #418ae0, #56a0d8, #dc8bc3, #56a0d8, #418ae0, #56a0d8, #dc8bc3, #56a0d8, #418ae0);
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    -webkit-background-clip: text;
    background-size: 200% 100%;
    animation: masked-animation 3s infinite linear;
}
.lt img{
    width: 100%;
    height: 100%;
}
@keyframes masked-animation {
    0% {
        background-position: 0 -100%;
    }
    100% {
        background-position: -100% 0;
    }
}
</style>