<template>
    <div>
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
<!--        <BgTwo/>  &lt;!&ndash;背景组件&ndash;&gt;-->
        <el-row>
            <el-col class="hidden-sm-and-down">
                <div v-for="con in filteredArray" :key="con.id" class="col-row">
                    <div class="container-top">
                        <img :src="con.user_img_data"
                             alt=""
                             style="width: 50px;height: 50px; border-radius: 50px; float: left;margin: 10px 0 0 10px;">
                        <!--头像-->
                        <div class="info">
                            <p style="margin-top: 10px">
                                {{ con.user_name }}
                            </p><!--昵称-->
                            <p style="font-size: 10px; color: dimgray">
                                发表于 {{ con.formatted_time }}
                            </p><!--时间-->
                        </div><!--昵称和时间-->
                    </div><!--帖子头部-->

                    <div style="margin: 30px auto 0 auto; width: 70vw;text-align: left; font-size:15px; font-weight: bolder;  word-break: break-all;">
                        {{ con.title }}
                    </div>

                    <div class="container-rom" v-html="con.content">

                    </div><!--帖子中间部分-->
                </div>
            </el-col>

            <el-col class="hidden-md-and-up">
                <div v-for="con in filteredArray" :key="con.id" class="min-col-row">
                    <div class="min-container-top">
                        <img :src="con.user_img_data"
                             alt=""
                             style="width: 30px;height: 30px; border-radius: 30px; float: left;margin: 10px 0 0 10px;">
                        <!--头像-->
                        <div class="min-info">
                            <p style="margin-top: 10px">
                                {{ con.user_name }}
                            </p><!--昵称-->
                            <p style="font-size: 8px; color: dimgray">
                                发表于 {{ con.formatted_time }}
                            </p><!--时间-->
                        </div><!--昵称和时间-->
                    </div><!--帖子头部-->

                    <div style="margin: 30px auto 0 auto; width: 70vw;text-align: left; font-size:15px; font-weight: bolder;  word-break: break-all;">
                        {{ con.title }}
                    </div>

                    <div class="min-container-rom" v-html="con.content">

                    </div><!--帖子中间部分-->
                </div>
            </el-col>
        </el-row>


        <el-form :inline="true" class="form-input hidden-sm-and-down">
            <el-form-item style="margin-right:0; border-radius: 10px 0;">
                <el-input v-model="input"  maxlength="200" show-word-limit clearable placeholder="请输入" style="width: 1280px; "></el-input>
            </el-form-item>


            <el-form-item style="width: 40px; margin-right: 0; text-align: center;">

                <el-button
                        style="width: 40px;height: 40px; border-radius: 40px; padding: 0;font-size: 25px; text-align: center;"
                        @click="toogleDialogEmoji">😀
                </el-button>
                <br>
                <VEmojiPicker v-show="showDialog"
                              :style="{ width: '100%', height: '200' }"
                              labelSearch="Search"
                              lang="pt-BR"
                              @select="selectEmoji">
                </VEmojiPicker>

            </el-form-item>


            <el-form-item style="margin-right: 0">
                <el-button style="width: 70px;" @click="UPcomment">发送</el-button>
            </el-form-item>
        </el-form>

        <el-form :inline="true" class="min-form-input hidden-md-and-up"
                 style="display: flex;justify-content: space-between;">
            <el-form-item style="margin-right:0; border-radius: 10px 0;">
                <el-input v-model="input" maxlength="200" show-word-limit clearable placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item style="margin-right: 0; text-align: center;">

                <el-button
                    style="width: 40px;height: 40px; border-radius: 40px; padding: 0;font-size: 25px; text-align: center;"
                    @click="toogleDialogEmoji">😀
                </el-button>
                <br>
                <VEmojiPicker v-show="showDialog" class="minemoji"
                              :style="{ width: '100%', height: '200' }"
                              labelSearch="Search"
                              lang="pt-BR"
                              @select="selectEmoji">
                </VEmojiPicker>


            </el-form-item>

            <el-form-item style="margin-right: 0">
                <el-button style="width: 70px;" @click="UPcomment">发送</el-button>
            </el-form-item>
        </el-form>

        <p style="background-color: #8492a6;border-radius: 5px; display: block;width: 200px;height: 30px; line-height: 30px; margin: 10px auto; text-align: center">
            评论区</p>

        <el-row v-if="publishFrom">

            <el-col class="hidden-sm-and-down">
                <div v-for="comment in dataArray" :key="comment.id" class="col-bot">
                    <div class="col-top">
                        <img :src="comment.comment_user_img_data"
                             alt=""
                             style="border-radius: 50px; width: 50px;height: 50px; float: left;margin: 10px 0 0 10px;">
                        <!--头像-->
                        <div class="info">
                            <p style="margin-top: 10px">
                                {{ comment.comment_user_username }}
                            </p>  <!--昵称-->
                            <p style="font-size: 8px; color: dimgray">
                                {{ comment.comment_created_at }}
                            </p>
                            <!--时间-->
                        </div>
                    </div>
                    <div class="col-bot-row">
                        {{ comment.comment_content }}
                    </div> <!--内容-->
                </div>
            </el-col>

            <el-col class="hidden-md-and-up">
                <div v-for="comment in dataArray" :key="comment.id" class="min-col-bot">
                    <div class="min-col-top">
                        <img :src="comment.comment_user_img_data"
                             alt=""
                             style="border-radius: 30px; width: 30px;height: 30px; float: left;margin: 10px 0 0 10px;">
                        <!--头像-->
                        <div class="min-info">
                            <p style="margin-top: 10px">
                                {{ comment.comment_user_username }}
                            </p>  <!--昵称-->
                            <p style="font-size: 8px; color: dimgray">
                                {{ comment.comment_created_at }}
                            </p>
                            <!--时间-->
                        </div>
                    </div>
                    <div class="min-col-bot-row">
                        {{ comment.comment_content }}
                    </div> <!--内容-->
                </div>
            </el-col>

        </el-row>

        <el-form v-else>
            <el-col>

                <el-empty :image="getRandomImage"  description="  "></el-empty>

            </el-col>
        </el-form>

    </div>
</template>

<script>

import axios from "axios";
import Loading from "@/components/Loading.vue";

// eslint-disable-next-line no-unused-vars
import {VEmojiPicker, emojisDefault, categoriesDefault} from 'v-emoji-picker'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "publish",
    components:
        {
            Loading,
            VEmojiPicker
        },
    data() {
        return {
            showDialog: false,
            publishFrom: true,
            isLoading: true,
            input: '', // 输入框的值
            inpit_emoji: [], //emoji的值
            dataArray: [],
            datalist: [],
            filteredArray: [],
            images: [
                './img/1.jpg',
                './img/2.jpg',
                './img/3.jpg',
                './img/4.jpg',
                './img/5.jpg',
            ],
        }
    },
    destroyed() {
        localStorage.removeItem("savedId");
        this.puId = null;
    },
    created() {
        this.puId = localStorage.getItem("savedId");
        this.getcom();
        this.getDta();

    },
    mounted() {
        // 添加键盘事件监听器到本组件内的元素
        this.$el.addEventListener('keydown', this.disableEnterKey);
    },
    beforeDestroy() {
        // 移除键盘事件监听器
        this.$el.removeEventListener('keydown', this.disableEnterKey);
    },
    computed:{
        getRandomImage() {
            const randomIndex = Math.floor(Math.random() * this.images.length);
            console.log(this.images[randomIndex])
            return this.images[randomIndex];

        },
    },
    methods: {
        toogleDialogEmoji() {
            this.showDialog = this.showDialog === false;
        },


        getcom() {
            const baseUrl = `http://${window.location.hostname}:9090`;
            axios.get(`${baseUrl}/comment`, {params: {id: this.puId}})
                .then(res => {
                    this.dataArray = res.data;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.publishFrom = false;
                    }
                });
        },

        // 根据id获取到帖子数据
        getDta() {
            const baseUrl = `http://${window.location.hostname}:9090`;
            axios.get(`${baseUrl}/post`)
                .then(response => {
                    this.datalist = response.data; // 设置原始数组数据
                    this.filteredArray = this.datalist.filter(item => item.id === parseInt(this.puId)); // 过滤出匹配项
                    this.filteredArray.forEach(item => {
                        item.content = this.formatContent(item.content);
                    });
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1900);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        // // 将 \n 转换为 <br> 标签
        formatContent(content) {
            return content.replace(/\n/g, '<br>');
        },

        UPcomment() {
            const baseUrl = `http://${window.location.hostname}:9090`;
            this.userId = localStorage.getItem('userId');
            if (!this.input.trim()) {
                return;
            }
            // 将当前登录用户的id和当前帖子的id发送到后端
            axios.post(`${baseUrl}/upcon`, {
                content: this.input,
                user_id: this.userId,
                post_id: this.filteredArray[0].id,
                created_at: new Date()  // 添加当前时间字段
            })
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                    // 这里可以不做任何处理，或者进行其他操作
                    this.input = ''; // 清空输入框的内容
                    this.getcom();   // 刷新评论列表
                    this.publishFrom = true; // 设置 publishFrom 为 true（如果这是你要做的操作）
                })
                .catch(error => {
                    // 处理错误响应
                    console.error(error);
                });
        },
        disableEnterKey(event) {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        },

        // 将v-emoji-picker中选择的表情添加到评论内容中
        selectEmoji(emoji) {
            this.input += emoji.data;
        },
    }
}
</script>

<style scoped>
/deep/ .emoji-picker {
    width: 325px !important;
    position: absolute !important;
    left: -150px;
    z-index: 10;
}
/deep/.minemoji{
    left: -200px;
}


.browBox {
    width: 100%;
    height: 200px;
    background: #e6e6e6;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    bottom: 0;
    overflow: scroll;

    ul {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;

        li {
            cursor: pointer;
            width: 10%;
            font-size: 26px;
            list-style: none;
            text-align: center;
        }
    }
}

.submit-btn {
    margin: 0 15px 10px 0;
    float: right;
}

/deep/.el-input__inner{
    padding-right:85px ;
}

.col-row {
    width: 1400px;
    border-radius: 5px;
    background-color: rgba(230, 230, 250, 0.7);
    margin: 0 auto;
    overflow: hidden;
}

.form-input {
    width: 1400px;
    height: 40px;
    margin: 10px auto;
}

/*内容*/
.container-top {
    width: 100%;
    height: 50px;
}

.info {
    margin-left: 100px;
    text-align: left;
    font-size: 15px;
    overflow: hidden;
}

.container-rom {
    width: 70vw;
    margin: 0 auto;
    padding: 15px 0 20px;
    text-align: left;
    font-size: 13px;
    font-weight: 200;
    word-break: break-all;
}


/*内容*/

/*评论区*/

.col-bot {
    width: 1400px;
    border-radius: 5px;
    background-color: rgba(240, 255, 255, 0.7);
    margin: 10px auto;
}

.col-top {
    margin-left: 20px;
    text-align: left;
    font-size: 15px;
    overflow: hidden;
}

.col-bot-row {
    width: 70vw;
    margin: 0 auto;
    padding: 20px 0;
    text-align: left;
    word-break: break-all;
    height: auto;
    text-indent: 2em;
    font-size: 14px;
}

/*评论区*/

/**/
.min-col-row {
    max-width: 992px;
    border-radius: 5px;
    background-color: rgba(230, 230, 250, 0.7);
    margin: 0 auto;
    padding: 0 10px;
    overflow: hidden;
}

.min-form-input {
    max-width: 992px;
    height: 40px;
    margin: 10px auto;
}

/*内容*/
.min-container-top {
    width: 100%;
    height: 50px;
}

.min-info {
    margin-left: 70px;
    text-align: left;
    font-size: 12px;
    overflow: hidden;
}

.min-container-rom {
    width: auto;
    padding: 15px 0 20px;
    text-align: left;
    font-size: 13px;
    font-weight: 200;
    word-break: break-all;
}


/*内容*/
/*评论区*/

.min-col-bot {
    width: auto;
    border-radius: 5px;
    background-color: rgba(240, 255, 255, 0.7);
    margin: 10px auto;
}

.min-col-top {
    margin-left: 20px;
    text-align: left;
    font-size: 15px;
    overflow: hidden;
}

.min-col-bot-row {
    width: 70vw;
    margin: 0 auto;
    padding: 20px 0;
    text-align: left;
    word-break: break-all;
    height: auto;
    text-indent: 2em;
    font-size: 14px;
}

/*评论区*/


/**/
</style>