<template>
<div>
    <bg-module/>  <!--背景组件-->
    <div>

        <div v-for="top in userData" :key="top.id" class="hidden-sm-and-down top">
            <div class="top-bg">
                <div class="tx">
                    <img :src="top.img_data" alt="Profile">
                </div>
                <div class="top-rom">
                    <div class="name">
                        {{ top.username }}
                    </div>
                    <div class="gender">
                        {{ top.gender }}
                    </div>
                    <div class="signature">
                        {{ top.sign }}
                    </div>
                </div>
                <el-row class="edit">
                    <el-button circle icon="el-icon-edit" type="primary" @click="go"></el-button>
                </el-row>
            </div>
        </div>

        <div class="TZ hidden-sm-and-down"></div>

        <el-row :gutter="20" class="hidden-sm-and-down"
                style="width:70vw;min-width:1200px; max-width: 1200px; margin: 0 auto;">
            <el-col :span="4" style="width: 150px; padding-left: 0;">
                <div class="row-l">
                    <router-link to='/personal/Personalposts'>
                        <el-button>个人帖子</el-button>
                    </router-link>
                    <router-link to='/personal/collection'>
                        <el-button style="margin: 10px 0;">收藏</el-button>
                    </router-link>
                </div>
            </el-col>
            <el-col :span="16" style="width:900px;">
                <router-view></router-view>
            </el-col>
        </el-row>


    </div>

    <div class="hidden-md-and-up">
        <div v-for="top in userData" :key="top.id" class="hidden-md-and-up min-top">
            <div class="min-top-bg">
                <div class="min-tx">
                    <img :src="top.img_data" alt="Profile">
                </div>
                <div class="min-top-rom">
                    <div class="min-name">
                        {{ top.username }}
                    </div>
                    <div class="min-gender">
                        {{ top.gender }}
                    </div>
                    <div class="min-signature">
                      {{ top.sign }}
                    </div>
                </div>
                <el-row class="min-edit">
                    <el-button circle icon="el-icon-edit" type="primary" @click="go"></el-button>
                </el-row>
            </div>
        </div>
        <div class="hidden-md-and-up min-row">

            <div class="min-row-l">
                <router-link to='/personal/Personalposts'>
                    <el-button style="margin-left: 5px;">个人帖子</el-button>
                </router-link>
            </div>

            <div class="min-row-l">
                <router-link to='/personal/collection'>
                    <el-button>收藏</el-button>
                </router-link>
            </div>
        </div>
        <el-row class="hidden-md-and-up" style="width: 100%;max-width: 992px; ">
            <router-view></router-view>
        </el-row>
    </div>
</div>
</template>


<script>
import axios from "axios";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "personal",
    data() {
        return {
            // username: '',
            ID: '',
            dataArray: [], // 原始
            filteredArray: [], // 过滤后
            userData: []
        }
    },
    created() {
        document.title = "个人小窝";
        this.ID = localStorage.getItem('userId');
        this.getuser(this.ID);
    },


    methods: {
        go() {
            this.$router.push('/revise')
        },
        getuser(id) {
            const baseUrl = `http://${window.location.hostname}:9090`;
            axios.get(`${baseUrl}/postuser?id=${id}`)
                .then(response => {
                    this.userData = response.data;
                }).catch(error => {
                console.error(error);
            })
        },


    }
}
</script>

<style scoped>
body {
    background-color: #55acee;
}

.TZ {
    width: 1200px;
    height: 30px;
    margin: 0 auto 10px;
    border-radius: 5px;
    line-height: 30px;
    text-align: left;
    text-indent: 2em;
    background-color: rgba(240, 255, 255, 0.8);

}

.top {
    background-color: rgba(255, 255, 255, 0.9);
    width: 70vw;
    height: 400px;
    margin: 0 auto 10px;
    min-width: 1045px;
    border-radius: 10px 10px 0 0;
    background-image: url("../assets/bj-2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.top-bg {
    width: 100%;
    height: 130px;
    margin-top: 270px;
    background-color: white;
}

.row-l {
    width: 150px;
    height: auto;
    min-height: 300px;
    border-radius: 5px;
    background-color: rgba(230, 230, 250, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row-l button {
    width: 150px;
    border: none;
}

/*个人介绍*/

.tx {
    float: left;
    margin-top: -30px;
    margin-left: 20px;
}

.tx img {
    width: 150px;
    height: 150px;
    border-radius: 150px;
}

.top-rom {
    float: left;
    width: 200px;
    height: 130px;
}

.name {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    line-height: 50px;
    font-size: 20px;
    font-weight: bolder;
}

.gender {
    font-size: 12px;
}

.signature {
    width: 100%;
    margin-top: 10px;
    font-size: 15px;
}

.edit {
    float: right;
    margin-top: 40px;
    margin-right: 40px;
}

/*个人介绍*/


/*小屏*/
.min-top {
    background-color: rgba(255, 255, 255, 0.9);
    max-width: 992px;
    width: 100%;
    height: 200px;
    margin: 0 auto 10px;
    border-radius: 10px 10px 0 0;
    background-image: url("../assets/bj-2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.min-top-bg {
    width: 100%;
    height: 130px;
    margin-top: 150px;
    background-color: white;
}

.min-row {
    width: 100%;
    max-width: 992px;
    height: 30px;
    display: flex;
    margin-bottom: 10px;
    justify-content: left;
    gap: 20px;
    background-color: white;
    border-radius: 10px;
}

.min-row-l button {
    width: auto;
    height: 30px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    padding: 0;
    font-size: 10px;
}

/*个人介绍*/

.min-tx {
    float: left;
    margin-top: -30px;
    margin-left: 10px;
}

.min-tx img {
    width: 70px;
    height: 70px;
    border-radius: 70px;
}

.min-top-rom {
    float: left;
    width: 150px;
    height: 130px;
    margin-left: 10px;
}

.min-name {
    width: 100%;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    font-weight: bolder;
}

.min-gender {
    font-size: 8px;
}

.min-signature {
    margin-top: 3px;
    font-size: 8px;
}

.min-edit {
    float: right;
    margin-top: 5px;
    margin-right: 20px;
}

/*个人介绍*/


/*小屏*/

</style>