import axios from 'axios';
const baseUrl = `http://${window.location.hostname}:9090`;

async function fetchtableRow() {
    try {
        const res = await axios.get(`${baseUrl}/post`);

        // await new Promise(resolve => setTimeout(resolve, 2000))

        // 格式化帖子内容
        res.data.forEach(post => {
            post.content = formatContent(post.content);
        });
        return res.data

    } catch (error) {
        console.error("获取失败", error);
    }
}

// 将 \n 转换为 <br> 标签
function formatContent(content) {
    return content.replace(/\n/g, '<br>');
}

export default {
    fetchtableRow
};
