import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import VueParticles from 'vue-particles'

Vue.use(VueParticles)


// 滑动验证码
// import PuzzleVerification from 'vue-puzzle-verification'
// // 使用封装的登录验证vue组件
// Vue.use(PuzzleVerification);
// 滑动验证码

// 图形验证码
import SIdentify from "@/components/SIdentify.vue"

Vue.component('SIdentify', SIdentify)
// 验证码

//componernts
import Navbartext from "@/components/Navbartext.vue";
import Weather from "@/components/Weather.vue";
import Meteor from "@/components/Meteor.vue";
import Sakura from '@/components/Sakura.vue'
import Login from "@/components/login.vue";
import Enroll from "@/components/enroll.vue"
import Modify from "@/components/modify.vue";
import Bg from "@/components/Bg.vue";
import BgTwo from "@/components/BgTwo.vue";
import GmaeBJ from "@/components/GameBJ.vue";
import Loading from "@/components/Loading.vue" //加载模块
import music from "@/components/music.vue";
import musicbox from "@/components/musicbox.vue";
import Loginout from "@/components/loginout.vue";

import MiniGames from "@/components/MiniGames.vue";
import MiniGamestwo from "@/components/MiniGamestwo.vue";
import MiniGamessnakes from "@/components/MiniGamessnakes.vue";
//views
import Homepage from "@/views/homepage.vue";
import Personal from "@/views/personal.vue";
import Publish from "@/views/publish.vue";
import Compose from "@/views/compose.vue";
import Revise from "@/views/revise.vue"
import GameSpace from "@/views/GameSpace.vue";
import Suosuo from "@/views/suosuo.vue";
//管理员路径
import UP from "@/Svip/UP.vue";


Vue.component('Loginout-module', Loginout)  //樱花组件
Vue.component('sakura-module', Sakura)  //樱花组件
Vue.component('navbar-text', Navbartext) //动态字体
Vue.component('meteor-module', Meteor)  //流星雨组件
Vue.component('weather-module', Weather) //时间组件
Vue.component('bg-module', Bg) //背景组件
Vue.component('BgTwo', BgTwo) //背景组件
Vue.component('GmaeBJ', GmaeBJ) //背景组件
// Vue.component('Loading-module', Loading)  //加载组件

Vue.component('music-module', music)
Vue.component('musicbox-module', musicbox)
//子路由
import collection from '@/views/Personal/collection';
import Personalposts from '@/views/Personal/Personalposts';
import Game from "@/views/gamegace/Game.vue";
import Anime from "@/views/gamegace/Anime.vue";
//管理员子路径
import administrator from '@/Svip/administrator.vue';
import upload from '@/Svip/upload.vue'

const router = new VueRouter({
    // mode: 'history', // 使用HTML5 history模式
    // mode: 'hash', // 使用HTML5 hash模式

    routes: [
        {path: '/', redirect: '/homepage'},

        {path: '/login', component: Login},

        {path: '/enroll', component: Enroll}, //注册

        {path: '/modify', component: Modify}, //忘记密码

        {path: '/MiniGames', component: MiniGames},  //井字棋小游戏

        {path: '/MiniGamestwo', component: MiniGamestwo},  //2048小游戏

        {path: '/MiniGamessnakes', component: MiniGamessnakes},  //贪吃蛇小游戏

        {path: '/Loading-module', component: Loading},//加载组件


        {path: '/homepage', name: 'homepage', component: Homepage,},

        {path: '/revise', name: 'revise', component: Revise,}, //修改页面

        {
            path: '/personal',
            name: 'personal',
            component: Personal,
            redirect: '/personal/Personalposts',
            children: [
                {
                    path: 'Personalposts',
                    component: Personalposts,
                },
                {
                    path: 'collection',
                    component: collection,
                }
            ]
        },
        {
            path: '/GameSpace',
            name: 'GameSpace',
            component: GameSpace,
            redirect: '/GameSpace/Game',
            children: [
                {
                    path: 'Game',
                    component: Game,
                },
                {
                    path: 'Anime',
                    component: Anime,
                }
            ]
        },//游戏空间

        {
            path: '/compose', name: 'compose', component: Compose,

        },

        {
            path: '/suosuo', name: 'suosuo', component: Suosuo,

        },

        {
            path: '/publish', name: 'publish', component: Publish,
            meta: {
                isLogin: true,
            }
        },
        {
            path: '/UP',
            name: 'UP',
            component: UP,
            redirect: '/UP/administrator',
            children: [
                {
                    path: 'administrator',
                    component: administrator,
                },
                {
                    path: 'upload',
                    component: upload,
                }
            ]
        }


    ]
})

//前置导航
import axios from "axios";
const baseUrl = `http://${window.location.hostname}:9090`;
router.beforeEach(async(to, from, next) =>{

    if (['/compose', '/publish', '/personal/Personalposts', '/personal/collection'].includes(to.path)) {
        const token = localStorage.getItem('token')
        if (token) {
            next()
        } else {
            next('/login')
        }
    } else {
        next();
    }
    // 获取管理员权限
    if (to.path === '/UP/administrator') {
        try {
            const id = localStorage.getItem('userId');
            const response = await axios.post(`${baseUrl}/min`, {
                id
            });
            const  permissions  = response.data.permissions;
            if ( permissions === 1 || permissions === 2) {
                next();
            } else {
                alert('您没有权限访问此页面');
                 next('/homepage'); // 中止导航
            }
        } catch (error) {
            console.error('获取权限失败', error);
             next('/homepage'); // 发生错误时也中止导航
        }
    } else {
        next();
    }

    // next();
});


export default router

