<template>
    <div>
        <LoginBG/>
        <div class="login_container hidden-sm-and-down">
            <div class="login_box">
                <!-- 头像区域 -->
                <div class="avatar_box">
                    <img alt="" src="../../public/images/2.gif"/>
                </div>
                <!-- 登录表单 -->
                <el-form ref="LoginFormRef" :model="loginForm" :rules="rules" class="login_form" label-width="0px">

                    <el-form-item prop="uid" style="margin-bottom: 25px;">
                        <el-input v-model="loginForm.uid" placeholder="账户名" prefix-icon="iconfont icon-user"
                                  @input="hideError"></el-input>
                    </el-form-item>

                    <el-form-item prop="password" style="margin-bottom: 40px;">
                        <el-input v-model="loginForm.password" placeholder="密码" prefix-icon="iconfont icon-3702mima"
                                  show-password @input="hideError"></el-input>
                    </el-form-item>

                    <router-link style="position: fixed;top: 245px;right: 21px; font-size: 10px;" to="/modify">
                        忘记密码
                    </router-link>

                    <div v-if="showError" class="error_message">账号或密码错误</div>
                    <div v-if="zh" class="error_message">请输入账号或密码</div>
                    <el-form-item class="btns">
                        <el-button round style="background-color: lightcoral; border: 1px solid #eee" type="primary"
                                   @click="Duck">登录
                        </el-button>
                        <el-button round style="background-color: #ddd; border: 1px solid #eee" type="info"
                                   @click="duck">
                            注册
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>

            <el-dialog :visible.sync="centerDialogVisible" center class="l-di" title="提示" width="30%">
                <span>错误太多次会自动跳转到修改密码界面哦,</span> &nbsp;
                <router-link to="/modify"><span>点我跳转到修改密码界面</span></router-link>
            </el-dialog>

        </div>


        <div class="min-login_container hidden-md-and-up">
            <div class="min-login_box">
                <!-- 头像区域 -->
                <div class="min-avatar_box">
                    <img alt="" src="../../public/images/2.gif"/>
                </div>
                <!-- 登录表单 -->
                <el-form ref="LoginFormRef" :model="loginForm" :rules="rules" class="min-login_form" label-width="0px">

                    <el-form-item prop="uid" style="margin-bottom: 25px;">
                        <el-input v-model="loginForm.uid" placeholder="账户名" prefix-icon="iconfont icon-user"
                                  @input="hideError"></el-input>
                    </el-form-item>

                    <el-form-item prop="password" style="margin-bottom: 30px;">
                        <el-input v-model="loginForm.password" placeholder="密码" prefix-icon="iconfont icon-3702mima"
                                  show-password @input="hideError"></el-input>
                    </el-form-item>

                    <router-link style="position: fixed;top: 170px;right: 21px; font-size: 10px;" to="/modify">
                        忘记密码
                    </router-link>

                    <div v-if="showError" class="min-error_message">账号或密码错误</div>
                    <div v-if="zh" class="min-error_message">请输入账号或密码</div>
                    <el-form-item class="min-btns">
                        <el-button round style="font-size: 8px; background-color: lightcoral; border: 1px solid #eee"
                                   type="primary"
                                   @click="Duck">登录
                        </el-button>
                        <el-button round style="font-size: 8px;background-color: #ddd; border: 1px solid #eee"
                                   type="info"
                                   @click="duck">
                            注册
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>

            <el-dialog :visible.sync="centerDialogVisible" center class="l-di" title="提示" width="30%">
                <span>错误太多次会自动跳转到修改密码界面哦,</span> &nbsp;
                <router-link to="/modify"><span>点我跳转到修改密码界面</span></router-link>
            </el-dialog>

        </div>
    </div>
</template>

<script>

import axios from "axios";
import LoginBG from "@/components/LoginBG.vue";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'login',
    components: {LoginBG},
    data() {
        return {
            centerDialogVisible: false,
            showError: false,
            zh: false,
            loginForm: {
                uid: "",
                password: "",
            },

            rules: {
                uid: [
                    {required: true, message: "请输入账号:)", trigger: "blur"},
                    {min: 5, max: 11, message: "长度在5 - 11个字符", trigger: "blur",},
                ],
                password: [
                    {required: true, message: "请输入密码~~", trigger: "blur"},
                    {min: 8, max: 8, message: "8个字符", trigger: "blur"},
                ],
            },
        };
    },
    created() {
        document.title = "请登录";
    },
    methods: {
        hideError() {
            this.showError = false;
            this.zh = false;
        },//隐藏错误

        duck() {
            this.$router.push('/enroll');
        },

        Duck() {
            const baseUrl = `http://${window.location.hostname}:9090`;
            const uid = this.loginForm.uid.trim();
            const password = this.loginForm.password.trim();
            // 检查用户名是否为空
            if (!uid || !password) {
                this.zh = true;
                return
            }
            // 发起POST请求
            axios.post(`${baseUrl}/user`, {uid, password,})
                .then(response => {
                    if (response.data.success) {
                        // 保存用户信息到本地存储
                        localStorage.setItem('userimg', response.data.img_data);
                        localStorage.setItem('username', response.data.username);
                        localStorage.setItem('userId', response.data.id);
                        localStorage.setItem('token', response.data.token);
                        // localStorage.setItem("permissions",response.data.permissions)
                        // 重定向到首页并刷新页面
                        this.$router.push("/homepage", () => {
                            location.reload();
                        });
                    } else {
                        this.showError = true;
                    }
                })
                .catch(error => {
                    console.log(error);
                    if (error.response && error.response.status === 401) {
                        this.showError = true;
                    } else if (error.response && error.response.status === 417) {
                        this.centerDialogVisible = true;
                    } else if (error.response && error.response.status === 429) {
                        alert('请三分钟之后再登录');
                    } else if (error.response && error.response.status === 418) {
                        this.$router.push('/modify');
                    }
                });
        }


    },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
}

/deep/ .el-input--prefix .el-input__inner {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.l-di {
  position: fixed;
  top: 30%;
}

.login_container {
  width: 450px;
  height: 100%;
  margin: 0 auto;
  transform: translateY(50%);
}

.error_message {
  position: fixed;
  font-size: 12px;
  color: red;
  margin: -10px 0 0 25px;
}

.login_box {
  width: 450px;
  height: 300px;
  backdrop-filter: blur(3px);
  border-radius: 3px;
  box-shadow: 0 0 10px #ddd; //阴影
  position: relative;

  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px; //头像图片和头像盒子的距离
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }

  .avatar_box img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }

}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

//

.min-login_container {
  width: 300px;
  height: 630px;
  margin: 0 auto;
  transform: translateY(20%);
}

.min-error_message {
  position: fixed;
  font-size: 12px;
  color: red;
  margin: -10px 0 0 25px;
}

.min-login_box {
  width: 300px;
  height: 250px;
  backdrop-filter: blur(3px);
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: 0 0 10px #ddd; //阴影
  position: relative;

  .min-avatar_box {
    height:80px;
    width: 80px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 5px; //头像图片和头像盒子的距离
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }

  .min-avatar_box img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }

}

.min-login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.min-btns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

</style>
