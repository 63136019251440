import axios from 'axios';

const baseUrl = `http://${window.location.hostname}:9090`;

async function adminData() {
    try {
        return await axios.get(`${baseUrl}/admin`); // 返回从服务器获取的数据
    } catch (error) {
        console.error("获取失败", error);
    }
}
export default {
    adminData
};
