<template>
    <button class="btn" @click="gobtn(product.id)">
        <svg class="icon" height="20.625" viewBox="0 0 17.503 15.625" width="20.503" xmlns="http://www.w3.org/2000/svg">
            <path id="Fill"
                  ref="heartPath"
                  :style="{ fill: likepost(product.id) ? 'rgb(177, 139, 189)' : 'white' }"
                  d="M8.752,15.625h0L1.383,8.162a4.824,4.824,0,0,1,0-6.762,4.679,4.679,0,0,1,6.674,0l.694.7.694-.7a4.678,4.678,0,0,1,6.675,0,4.825,4.825,0,0,1,0,6.762L8.752,15.624ZM4.72,1.25A3.442,3.442,0,0,0,2.277,2.275a3.562,3.562,0,0,0,0,5l6.475,6.556,6.475-6.556a3.563,3.563,0,0,0,0-5A3.443,3.443,0,0,0,12.786,1.25h-.01a3.415,3.415,0,0,0-2.443,1.038L8.752,3.9,7.164,2.275A3.442,3.442,0,0,0,4.72,1.25Z"
                  transform="translate(0 0)"></path>
        </svg>
    </button>

</template>

<script>
import axios from "axios";
import {MessageBox, Notification} from 'element-ui';

export default {

    // eslint-disable-next-line vue/multi-word-component-names
    name: "like",
    data() {
        return {
            userLovePosts: []
        }
    },
    created() {
        this.like();
        this.likepost();
    },

    props: {
        product: Object // 声明接收名为 product 的 prop
    },
    methods: {
        // like(){
        //     let id = parseInt(localStorage.getItem('userId'));
        //     let baseUrl = `http://${window.location.hostname}:9090`;
        //     axios.get(`${baseUrl}/getuserlove?id=${id}`)
        //         .then(response => {
        //             this.userLovePosts = response.data;
        //         }).catch(response=>{
        //         if (response.data==="没有喜欢的"){
        //             console.log("没有喜欢的，快去收藏")
        //         }
        //     })
        // },
        // likepost(ID){
        //     return  this.userLovePosts.some(id => id === ID)
        // },
        like() {
            let id = parseInt(localStorage.getItem('userId'));
            let baseUrl = `http://${window.location.hostname}:9090`;
            axios.get(`${baseUrl}/getuserlove?id=${id}`)
                .then(response => {
                    this.userLovePosts = response.data;
                    // 在成功获取数据后执行 likepost(ID) 方法
                    if (this.userLovePosts.length > 0) {
                        this.likepost();
                    } else {
                        console.log("没有喜欢的，快去收藏");
                    }
                }).catch(response => {
                if (response.data === "没有喜欢的") {
                    console.log("没有喜欢的，快去收藏");
                }
            });
        },

        likepost(ID) {
            // 在执行前检查 this.userLovePosts 是否存在并且是一个数组
            if (Array.isArray(this.userLovePosts)) {
                return this.userLovePosts.some(id => id === ID);
            } else {
                return false;
            }
        },


        gobtn(id) {
            const token = localStorage.getItem('token')
            if (token) {
                let user_id = parseInt(localStorage.getItem('userId'));
                let baseUrl = `http://${window.location.hostname}:9090`;
                axios.post(`${baseUrl}/userlove`, {
                    params: {user_id, id}
                }).then(response => {
                    if (response.data === "200") {
                        this.$refs.heartPath.style.fill = "purple"; // 修改颜色为紫色
                        Notification({
                            title: '收藏成功',
                            type: 'success'
                        });
                    }
                    if (response.data === "500") {
                        MessageBox.confirm('确定要取消收藏吗？Σ(っ°Д°;)っ', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            let user_id = parseInt(localStorage.getItem('userId'));
                            axios.delete(`${baseUrl}/Deleteuserlove`, {params: {user_id, id}})
                                .then(response => {
                                    if (response.data === "取消收藏成功") {
                                        this.$refs.heartPath.style.fill = "white"; // 修改颜色为白色
                                        Notification({
                                            title: '成功取消收藏',
                                            type: 'success'
                                        });
                                    }
                                    if (response.data === "取消收藏失败") {
                                        Notification({
                                            title: '取消收藏失败，请稍后再试',
                                            type: 'success'
                                        });
                                    }
                                })

                        }).catch(() => {

                        });
                    }
                })
            } else {
                this.$router.push('login')
            }
        }
    }
}
</script>

<style scoped>
.btn {
    width: 30px;
    height: 20px;
    border-radius: 10px;
    border: none;
    background-color: transparent;
    position: relative;
    float: left;
}

.icon {
    width: 20px;
    height: 20px;
    transition: .2s linear;
    top: 5px;
    left: 0;
    transform: none;
}

.icon path {
    fill: white;
    transition: .2s linear;
}


.btn:hover > .icon {
    transform: scale(1.2);
}

.btn:hover > .icon path {
    fill: rgb(177, 139, 189);
}

</style>