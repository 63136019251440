<template>
    <div>
        <div class="hidden-sm-and-down">
            <el-row v-if="dis">
                <el-col>
                    <div v-for="con in dataArray" :key="con.id" class="row-con">
                        <div class="container-top">
                            <img :src="con.user_img_data"
                                 alt=""
                                 style="width: 50px;height: 50px; border-radius: 50px; float: left;margin: 10px 0 0 10px;">
                            <!--头像-->
                            <div class="info">
                                <p style="margin-top: 10px">
                                    {{ con.user_name }}
                                </p><!--昵称-->
                                <p style="font-size: 10px; color: dimgray">
                                    发表于 {{ con.formatted_time }}
                                </p><!--时间-->
                            </div><!--昵称和时间-->

                            <div style="width:100px;height:40px; float: right;padding-top: 10px;">
                                <button class="btn" @click="gobtn(con.id)">
                                    <svg class="icon" height="20.625" viewBox="0 0 17.503 15.625"
                                         width="20.503" xmlns="http://www.w3.org/2000/svg">
                                        <path id="Fill"
                                              ref="heartPath"
                                              :style="{ fill: likepost(con.id) ? 'rgb(177, 139, 189)' : 'white' }"
                                              d="M8.752,15.625h0L1.383,8.162a4.824,4.824,0,0,1,0-6.762,4.679,4.679,0,0,1,6.674,0l.694.7.694-.7a4.678,4.678,0,0,1,6.675,0,4.825,4.825,0,0,1,0,6.762L8.752,15.624ZM4.72,1.25A3.442,3.442,0,0,0,2.277,2.275a3.562,3.562,0,0,0,0,5l6.475,6.556,6.475-6.556a3.563,3.563,0,0,0,0-5A3.443,3.443,0,0,0,12.786,1.25h-.01a3.415,3.415,0,0,0-2.443,1.038L8.752,3.9,7.164,2.275A3.442,3.442,0,0,0,4.72,1.25Z"
                                              transform="translate(0 0)"></path>
                                    </svg>
                                </button>
                            </div>

                        </div><!--帖子头部-->
                        <div style="margin: 30px auto 0 auto; width:800px;text-align: left; font-size:15px; font-weight: bolder;  word-break: break-all;">
                            {{ con.title }}
                        </div>
                        <div class="container-rom" @click="saveId(con.id)" v-html="con.content">

                        </div><!--帖子中间部分-->

                    </div> <!--内容-->
                </el-col>
            </el-row>

            <el-form v-else>
                <el-col>
                    <el-empty :image-size="200" :description="description"></el-empty>
                </el-col>
            </el-form>

        </div>

        <div class="hidden-md-and-up">
            <el-row v-if="dis">
                <el-col>
                    <div v-for="con in dataArray" :key="con.id" class="min-row-con">
                        <div class="min-container-top">
                            <img :src="con.user_img_data"
                                 alt=""
                                 style="width: 50px;height: 50px; border-radius: 50px; float: left;margin: 10px 0 0 10px;">
                            <!--头像-->
                            <div class="min-info">
                                <p style="margin-top: 10px">
                                    {{ con.user_name }}
                                </p><!--昵称-->
                                <p style="font-size: 10px; color: dimgray">
                                    发表于 {{ con.formatted_time }}
                                </p><!--时间-->
                            </div><!--昵称和时间-->

                            <div style="width:50px;height:40px; float: right;padding-top: 10px;">
                                <button class="btn" @click="gobtn(con.id)">
                                    <svg class="icon" height="20.625" viewBox="0 0 17.503 15.625"
                                         width="20.503" xmlns="http://www.w3.org/2000/svg">
                                        <path id="Fill"
                                              ref="heartPath"
                                              :style="{ fill: likepost(con.id) ? 'rgb(177, 139, 189)' : 'white' }"
                                              d="M8.752,15.625h0L1.383,8.162a4.824,4.824,0,0,1,0-6.762,4.679,4.679,0,0,1,6.674,0l.694.7.694-.7a4.678,4.678,0,0,1,6.675,0,4.825,4.825,0,0,1,0,6.762L8.752,15.624ZM4.72,1.25A3.442,3.442,0,0,0,2.277,2.275a3.562,3.562,0,0,0,0,5l6.475,6.556,6.475-6.556a3.563,3.563,0,0,0,0-5A3.443,3.443,0,0,0,12.786,1.25h-.01a3.415,3.415,0,0,0-2.443,1.038L8.752,3.9,7.164,2.275A3.442,3.442,0,0,0,4.72,1.25Z"
                                              transform="translate(0 0)"></path>
                                    </svg>
                                </button>
                            </div>

                        </div><!--帖子头部-->
                        <div style="margin: 30px auto 0 auto;text-align: left; font-size:15px; font-weight: bolder;  word-break: break-all;">
                            {{ con.title }}
                        </div>
                        <div class="min-container-rom" @click="saveId(con.id)" v-html="con.content">

                        </div><!--帖子中间部分-->

                    </div> <!--内容-->
                </el-col>
            </el-row>

            <el-form v-else>
                <el-col>
                    <el-empty :image-size="100" :description="description"></el-empty>
                </el-col>
            </el-form>
        </div>
    </div>


</template>

<script>
import axios from "axios";
import {Message, MessageBox, Notification} from "element-ui";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "collection",
    data() {
        return {
            description:"暂无收藏",
            dataArray: [],
            userLovePosts: [],
            dis: true,
        }
    },
    created() {
        document.title = "收藏";

    },

    mounted() {
        this.getlike(); //获取收藏帖子的id
        this.getData(); //获取收藏帖子
        this.likepost(); //对比渲染颜色
    },
    methods: {
        getlike() {
            let id = parseInt(localStorage.getItem('userId'));
            let baseUrl = `http://${window.location.hostname}:9090`;
            axios.get(`${baseUrl}/getuserlove?id=${id}`)
                .then(response => {
                    if (response.data === "没有喜欢的") {
                        console.log( "快去收藏帖子吧")
                    } else {
                        this.userLovePosts = response.data;
                    }
                })
        },


        saveId(id) {
            localStorage.setItem('savedId', id);
            const newWindow = window.open('/#/publish', '_blank');
            newWindow.focus();
        },
        getData() {
            let id = parseInt(localStorage.getItem('userId'));//
            const baseUrl = `http://${window.location.hostname}:9090`;
            axios.get(`${baseUrl}/getuserlovecon?id=${id}`)
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        // 如果存在数据，则执行后续操作
                        this.dataArray = response.data.data;
                        this.dataArray.forEach(item => {
                            item.content = this.formatContent(item.content);
                        });
                        this.dis = true;
                    } else {
                        // 如果不存在数据，则不执行后续操作
                        this.dis = false;
                    }
                })
        },
        // // 将 \n 转换为 <br> 标签
        formatContent(content) {
            return content.replace(/\n/g, '<br>');
        },


        likepost(ID) {
            return this.userLovePosts.some(id => id === ID)
        },

        gobtn(id) {
            let user_id = parseInt(localStorage.getItem('userId'));
            let baseUrl = `http://${window.location.hostname}:9090`;
            axios.post(`${baseUrl}/userlove`, {
                params: {user_id, id}
            }).then(response => {
                if (response.data === "200") {
                    this.$refs.heartPath.style.fill = "purple"; // 修改颜色为紫色
                    Notification({
                        title: '收藏成功',
                        type: 'success'
                    });
                }
                if (response.data === "500") {
                    MessageBox.confirm('确定要取消收藏吗？Σ(っ°Д°;)っ', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        let user_id = parseInt(localStorage.getItem('userId'));
                        axios.delete(`${baseUrl}/Deleteuserlove`, {params: {user_id, id}})
                            .then(response => {
                                if (response.data === "取消收藏成功") {
                                    Notification({
                                        title: '成功取消收藏',
                                        type: 'success'
                                    });
                                    location.reload();
                                }
                                if (response.data === "取消收藏失败") {
                                    Notification({
                                        title: '取消收藏失败，请稍后再试',
                                        type: 'success'
                                    });
                                }

                            })
                        Message({
                            type: 'success',
                            message: '取消收藏成功! (꒪Д꒪)ノ '
                        });

                    }).catch(() => {
                        Message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                }
            })
        },

    }
}
</script>

<style scoped>
.col-bot {
    width: 100%;
    min-height: 30px;
    line-height: 30px;
    border-radius: 5px;
    background-color: rgba(240, 255, 255, 0.7);
    margin: 10px auto;
}
/deep/.el-empty__description p{
    color: black;
}
/*内容*/
.row-con {
    height: auto;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: rgba(240, 255, 255, 0.5);
}

.container-top {
    width: 100%;
    height: 50px;
}

.info {
    margin-left: 80px;
    text-align: left;
    font-size: 13px;
    overflow: hidden;

}

.container-rom {
    width: 800px;
    margin: 0 auto;
    padding: 20px 0;
    word-break: break-all;
    text-align: left;
    height: auto;
    font-size: 14px;
    font-weight: 200;
}

/*内容*/
/**/
/*内容*/
.min-row-con {
    max-width: 992px;
    height: auto;
    padding: 0 10px 10px 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: rgba(240, 255, 255, 0.5);
}

.min-container-top {
    width: 100%;
    height: 50px;
}

.min-info {
    margin-left: 30px;
    text-align: left;
    font-size: 13px;
    overflow: hidden;
    float: left;
}

.min-container-rom {
    width: auto;
    margin: 10px auto;
    text-align: justify;
    font-size: 10px;
    font-weight: 200;
    max-height: 66px;
    /* 在恰当的断字点进行换行 */
    word-break: break-all;
    /* 超出范围隐藏 */
    overflow: hidden;
    /* 文字超出用省略号 */
    text-overflow: ellipsis;
    /* 盒子模型 */
    display: -webkit-box;
    /* 显示的文本行数 */
    -webkit-line-clamp: 5;
    /* 子元素的垂直排列方式 */
    -webkit-box-orient: vertical;
}

/**/


.btn {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    border-radius: 10px;
    border: none;
    background-color: transparent;
    position: relative;
    float: left;
}

.icon {
    width: 20px;
    height: 20px;
    transition: .2s linear;
    top: 5px;
    left: 0;
    transform: none;
}

.icon path {
    fill: white;
    transition: .2s linear;
}


.btn:hover > .icon {
    transform: scale(1.2);
}

.btn:hover > .icon path {
    fill: rgb(177, 139, 189);
}


</style>