import Vue from 'vue'
import App from './App.vue'

import './plugins/element.js'
import router from './router/index'
import './assets/styles/style.css'; // 引入全局样式

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
