<template>
        <div class="bg">
        <iframe frameborder="0" src="/Game.html" style="width: 100%;height:100%"></iframe>
        </div>
</template>

<script>
export default {
    name: "MiniGames",
}
</script>

<style scoped>
.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../assets/ddk.gif);
}
</style>