<template>
    <div>
        <div class="bg"></div>
    </div>
</template>

<script>
export default {
    name: "LoginBG"
}
</script>

<style scoped>
.bg {
    position: fixed;
    background-image: url("@/assets/log3.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -90;
}
</style>