<template>
<div>
    <div class="bg">
        <iframe frameborder="0" src="/GameSnakes.html" style="width: 100%;height:100%"></iframe>
    </div>
</div>
</template>

<script>
export default {
    name: "MiniGamessnakes"
}
</script>

<style scoped>
.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../public/images/5.gif);
}
</style>