<template>
<div>
    <div class="bg">
    <iframe frameborder="0" src="/GameTwo.html" style="width: 100%;height:100%"></iframe>
    </div>
</div>
</template>

<script>
export default {
    name: "MiniGamestwo"
}
</script>

<style scoped>
.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>