<template>
    <div class="games">
        <div class="games-box">
            <router-link to='/MiniGames'>
                <img alt="" src="/images/1.gif">
            </router-link>
            <h1>井字棋</h1>
            <p>一款小游戏</p>
        </div>

        <div class="games-box">
            <router-link to='/MiniGamestwo'>
                <img alt="" src="/images/3.gif">
            </router-link>
            <h1>2048</h1>
            <p>1111</p>
        </div>
        <div class="games-box">
            <router-link to='/MiniGamessnakes'>
                <img alt="" src="/images/6.gif">
            </router-link>
            <h1>贪吃蛇</h1>
            <p>111111</p>
        </div>
        <div class="games-box">
            <h1>占位</h1>
            <p>占位</p>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Game"
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.games {
    margin: 20px auto;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.games-box {
    width: 280px;
    height: 90px;
    background-color: #dddddd;
    border-radius: 15px;
}

.games-box a {
    display: block;
    width: 90px;
    height: 90px;
    float: left;
    border-radius: 15px;
}

.games-box h1 {
    text-align: left;
    text-indent: 2em;
    float: left;
    width: 180px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
}

.games-box p {
    float: left;
    margin-left: 20px;
    max-height: 70px;
    font-size: 12px;
    width: 160px;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.games-box img {
    width: 90px;
    height: 90px;
    border-radius: 15px;
}

</style>