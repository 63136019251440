<template>
    <div>
        <div class="loader1" :class="{ split: isSplit }"></div>
        <div class="circle" :class="{ expand: isExpand, fade: isSplit }"></div>
        <div class="loader2" :class="{ split: isSplit }"></div>
    </div>
</template>

<script>
export default {
    name: "LoadingTwo",
    data() {
        return {
            isExpand: false,
            isSplit: false,
        };
    },
    mounted() {
        // 先执行扩散和淡出动画
        setTimeout(() => {
            this.isExpand = true;
        }, 1500);

        // 扩散动画完成后，开始上下分离动画
        setTimeout(() => {
            this.isSplit = true;
        }, 3000);
    },
}
</script>

<style scoped>
.loader1, .loader2 {
    position: fixed;
    left: 0;
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    z-index: 100;
    transition: transform 1.5s ease;
}

.loader1 {
    top: 0;
}

.loader2 {
    bottom: 0;
}

.circle {
    width: 150px;
    height: 150px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    background-image: url("/public/LoadingGIF/1.gif");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 1.8s ease, opacity 0.1s ease;
}

/* Circle 扩散动画 */
.circle.expand {
    transform: translate(-50%, -50%) scale(1.1);
}

/* Circle 淡出动画 */
.circle.fade {
    opacity: 0;
}

/* Loader 分离动画 */
.loader1.split {
    transform: translateY(-100%);
}

.loader2.split {
    transform: translateY(100%);
}
</style>