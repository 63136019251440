<template>
    <div>

        <div class="login_container  hidden-sm-and-down">
            <div class="login_box">
                <!--隐藏-->
                <el-form v-if="LoginFormIf" ref="LoginFormRef" :model="loginForm" :rules="rules" class="login_form"
                         label-width="0px">
                    <p style="margin-bottom: 20px">修改密码</p>

                    <el-form-item prop="two" style="margin-bottom: 25px;">
                        <el-input v-model="loginForm.two" :show-password="true" placeholder="密码"
                                  prefix-icon="iconfont icon-3702mima"  @input="hideError">
                        </el-input>
                    </el-form-item>

                    <el-form-item prop="password" style="margin-bottom: 25px;">
                        <el-input v-model="loginForm.password" :show-password="true" placeholder="确认密码"
                                  prefix-icon="iconfont icon-3702mima"  @input="hideError">
                        </el-input>
                    </el-form-item>

                    <el-form-item prop="Captcha" style="margin-bottom: 20px;">
                        <el-input v-model="loginForm.Captcha" clearable placeholder="请输入验证码"
                                  style="width: 150px;float: left;" @input="isCodeCorrect=false"/>
                        <div style="float: right" @click="refreshCode">
                            <!--验证码组件-->
                            <s-identify :identifyCode="identifyCode"></s-identify>
                        </div>
                        <div v-if="isCodeCorrect" style="color: red;">验证码错误</div>
                    </el-form-item>

                    <div v-if="showError" class="error_message">两个密码不一致</div>

                    <el-form-item class="btns">
                        <el-button round style="background-color: lightcoral; border: 1px solid #eee" type="primary"
                                   @click="Duck">确定
                        </el-button>
                    </el-form-item>
                </el-form>


                <el-form v-else ref="modifyFormRef" :model="modifyFrom" :rules="rules" class="modify_form" label-width="0px">
                    <p style="margin-bottom: 40px;text-align: left;font-size: 15px;">输入要修改密码的账号</p>
                    <el-form-item prop="uid" style="margin-bottom: 50px;">
                        <el-input v-model="modifyFrom.uid" placeholder="账户名" prefix-icon="iconfont icon-user" @input="showErrors=false;">
                        </el-input>
                    </el-form-item>
                    <div v-if="showErrors" class="error_message">未查询到该账户名</div>
                    <el-form-item class="btns">
                        <el-button round style="background-color: lightcoral; border: 1px solid #eee" type="primary"
                                   @click="duck">确定
                        </el-button>
                    </el-form-item>

                </el-form>


            </div>
        </div>


        <div class="min-login_container hidden-md-and-up">
            <div class="min-login_box">
                <!--隐藏-->
                <el-form v-if="LoginFormIf" ref="LoginFormRef" :model="loginForm" :rules="rules" class="min-login_form"
                         label-width="0px">
                    <p style="margin-bottom: 20px">修改密码</p>

                    <el-form-item prop="two" style="margin-bottom: 10px;">
                        <el-input v-model="loginForm.two" :show-password="true" placeholder="密码"
                                  prefix-icon="iconfont icon-3702mima"  @input="hideError">
                        </el-input>
                    </el-form-item>

                    <el-form-item prop="password" style="margin-bottom: 10px;">
                        <el-input v-model="loginForm.password" :show-password="true" placeholder="确认密码"
                                  prefix-icon="iconfont icon-3702mima"  @input="hideError">
                        </el-input>
                    </el-form-item>

                    <div v-if="showError" class="error_message">两个密码不一致</div>

                    <el-form-item class="min-btns">
                        <el-button round style="background-color: lightcoral; border: 1px solid #eee" type="primary"
                                   @click="submit">确定
                        </el-button>
                    </el-form-item>
                </el-form>

                <el-form v-else ref="modifyFormRef" :model="modifyFrom" :rules="rules" class="min-modify_form" label-width="0px">
                    <p style="margin-bottom: 40px;text-align: left;font-size: 15px;">输入要修改密码的账号</p>
                    <el-form-item prop="uid" style="margin-bottom: 50px;">
                        <el-input v-model="modifyFrom.uid" placeholder="账户名" prefix-icon="iconfont icon-user" @input="showErrors=false;">
                        </el-input>
                    </el-form-item>
                    <div v-if="showErrors" class="error_message">未查询到该账户名</div>
                    <el-form-item class="btns">
                        <el-button round style="background-color: lightcoral; border: 1px solid #eee" type="primary"
                                   @click="duck">确定
                        </el-button>
                    </el-form-item>

                </el-form>

                <Vcode
                    :show="isShow"
                    @close="close"
                    @fail='fail'
                    @success="success"
                    :canvasWidth = "300"
                    :imgs="[Img1, Img2]"
                ></Vcode>

            </div>
        </div>

    </div>


</template>

<script>
import axios from "axios";
import SIdentify from "@/components/SIdentify.vue"
import {MessageBox} from "element-ui";
import Vcode from "vue-puzzle-vcode";

export default {
    components: {Vcode},
    comments: {SIdentify},
    // eslint-disable-next-line vue/multi-word-component-names
    name: "modify",
    data() {
        return {
            Img1:'/images/1.gif',
            Img2:'',
            Img3:'',
            Img4:'',
            isShow: false,
            LoginFormIf: false,
            showErrors: false,
            showError: false,
            // 验证码
            isCodeCorrect: false,
            identifyCode: '',
            identifyCodes: ['0', '1', '2', '3', ...'a', 'b', 'c', ...'z'],//验证码出现的数字和字母
            // 验证码

            modifyFrom: {
                uid: "",
            },

            loginForm: {
                password: "",
                two: "",
                Captcha: "",

            },
            rules: {
                uid: [
                    {required: true, message: "请输入账号:)", trigger: "blur"},
                    {min: 5, max: 11, message: "长度在5-11个字符", trigger: "blur",},
                ],
                password: [
                    {required: true, message: "请输入密码~~", trigger: "blur"},
                    {min: 8, max: 8, message: "长度在8个字符", trigger: "blur"},
                ],
                two: [
                    {required: true, message: "请输入密码~~", trigger: "blur"},
                    {min: 8, max: 8, message: "长度在8个字符", trigger: "blur"},
                ],
                Captcha: [
                    {required: true, message: "请输入验证码", trigger: "blur"}
                ],
            },
        }
    },

// 初始化验证码
    mounted() {
        this.identifyCode = ''
        this.makeCode(this.identifyCode, 4)
    },

    methods: {
        hideError(){
            this.showError=false;
        },
        // 更新验证码
        refreshCode() {
            this.identifyCode = ''
            this.makeCode(this.identifyCodes, 4)
        },
        makeCode(o, l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
            }
        },
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min);
        },
        //
        //确认账号
        async duck() {
            const uid = this.modifyFrom.uid.trim();

            if (uid.length > 11 || uid.length < 5) {return;}
            try {
                const baseUrl = `http://${window.location.hostname}:9090`;
                const response = await axios.post(`${baseUrl}/modify`, {uid,});
                if (response.data.success) {
                    localStorage.setItem('ID', response.data.id);
                    this.LoginFormIf = true
                }
            } catch (error) {
                if (error.response.status === 401) {
                    this.showErrors = true;
                }
            }
        },

        //发送密码
        async Duck() {
            this.ID = localStorage.getItem('ID');
            const password = this.loginForm.password.trim();
            const two = this.loginForm.two.trim();
            const captcha = this.loginForm.Captcha.trim();

            if (password.length < 8 || password.length > 8) {
                return;
            }

            if (two !== password) {
                this.showError = true;
                return;
            }

            if (captcha.toLowerCase() !== this.identifyCode.toLowerCase()) {
                this.isCodeCorrect = true;
                return;
            }

            try {
                const response = await axios.post('http://localhost:9090/modifys',
                    {
                        id: this.ID,
                        password: password
                    });
                if (response.data.success) {
                    localStorage.removeItem('ID');
                    MessageBox.alert("修改成功，即将跳转到登录界面", "提示", {
                        type: "success",
                        duration: 3000,
                        callback: () => {
                            this.$router.push("/login");
                        },
                    });
                }
            } catch (error) {
                if (error.response.status === 401) {
                    alert('寄');
                }
            }
        },

        submit(){
            this.isShow = true;
        },
        // 用户点击遮罩层，应该关闭模态框
        close(){
            this.isShow = false;
        },
        fail(){
            console.log('验证失败');
        },
        async success(){
            this.ID = localStorage.getItem('ID');
            this.isShow = false; // 通过验证后，需要手动隐藏模态框
            const password = this.loginForm.password.trim();
            const two = this.loginForm.two.trim();

            if (password.length < 8 || password.length > 8) {
                return;
            }

            if (two !== password) {
                this.showError = true;
                return;
            }

            try {
                const response = await axios.post('http://localhost:9090/modifys',
                    {
                        id: this.ID,
                        password: password
                    });
                if (response.data.success) {
                    localStorage.removeItem('ID');
                    MessageBox.alert("修改成功，即将跳转到登录界面", "提示", {
                        type: "success",
                        duration: 3000,
                        callback: () => {
                            this.$router.push("/login");
                        },
                    });
                }
            } catch (error) {
                if (error.response.status === 401) {
                    alert('寄');
                }
            }
        },

    },
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
}


.login_container {
  height: 100%;
}

.error_message {
  position: fixed;
  font-size: 12px;
  color: red;
  margin: -10px 0 0 25px;
}

.login_box {
  width: 450px;
  height: 300px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px #ddd; //阴影
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);

  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px; //头像图片和头像盒子的距离
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }

  .avatar_box img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }

}

.modify_form {
  position: absolute;
  bottom: 12%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
//
.min-login_container {
    height: 100%;
}

.min-error_message {
    position: fixed;
    font-size: 12px;
    color: red;
    margin: -10px 0 0 25px;
}

.min-login_box {
    width: 300px;
    height: 250px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 10px #ddd; //阴影
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);

    .min-avatar_box {
        height: 130px;
        width: 130px;
        border: 1px solid #eee;
        border-radius: 50%;
        padding: 10px; //头像图片和头像盒子的距离
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
    }

    .min-avatar_box img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
    }

}

.min-modify_form {
    position: absolute;
    bottom: 12%;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

.min-login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

.min-btns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}
//
</style>