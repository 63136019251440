<template>
    <div>
        <div class="hidden-sm-and-down">
            <div v-for="con in filteredArray" :key="con.id" class="row-con ">

                <div class="container-top">
                    <img :src="con.user_img_data"
                         alt=""
                         style="width: 50px;height: 50px; border-radius: 50px; float: left;margin: 10px 0 0 10px;">
                    <!--头像-->
                    <div class="info">
                        <p style="margin:10px 0 0">
                            {{ con.user_name }}
                        </p><!--昵称-->
                        <p style="font-size: 10px; color: dimgray">
                            发表于 {{ con.formatted_time }}
                        </p><!--时间-->
                    </div><!--昵称和时间-->

                    <div style="width:100px;height:40px; float: right;padding-top: 10px;">
                        <button class="bin-button" @click="gobtn(con.id)">
                            <svg class="bin-top" fill="none" viewBox="0 0 39 7" xmlns="http://www.w3.org/2000/svg">
                                <line stroke="white" stroke-width="4" x2="39" y1="5" y2="5"></line>
                                <line stroke="white" stroke-width="3" x1="12" x2="26.0357" y1="1.5" y2="1.5"></line>
                            </svg>
                            <svg class="bin-bottom" fill="none" viewBox="0 0 33 39" xmlns="http://www.w3.org/2000/svg">
                                <mask id="path-1-inside-1_8_19" fill="white">
                                    <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
                                </mask>
                                <path d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                                      fill="white" mask="url(#path-1-inside-1_8_19)"></path>
                                <path d="M12 6L12 29" stroke="white" stroke-width="4"></path>
                                <path d="M21 6V29" stroke="white" stroke-width="4"></path>
                            </svg>
                        </button>
                    </div>


                </div><!--帖子头部-->
                <div style="margin: 30px auto 0 auto; width:800px;text-align: left; font-size:15px; font-weight: bolder;  word-break: break-all;">
                    {{ con.title }}
                </div>
                <div class="container-rom" @click="saveId(con.id)" v-html="con.content">

                </div><!--帖子中间部分-->

            </div> <!--内容-->
        </div>

        <div class="hidden-md-and-up">
            <div v-for="con in filteredArray" :key="con.id" class="min-row-con ">
                <div class="min-container-top">
                    <img :src="con.user_img_data"
                         alt=""
                         style="width: 50px;height: 50px; border-radius: 50px; float: left;margin: 10px 0 0 10px;">
                    <!--头像-->
                    <div class="min-info">
                        <p style="margin:10px 0 0">
                            {{ con.user_name }}
                        </p><!--昵称-->
                        <p style="font-size: 10px; color: dimgray">
                            发表于 {{ con.formatted_time }}
                        </p><!--时间-->
                    </div><!--昵称和时间-->

                    <div style="width:50px;height:40px; float: right;padding-top: 10px;">
                        <button class="bin-button" @click="gobtn(con.id)">
                            <svg class="bin-top" fill="none" viewBox="0 0 39 7" xmlns="http://www.w3.org/2000/svg">
                                <line stroke="white" stroke-width="4" x2="39" y1="5" y2="5"></line>
                                <line stroke="white" stroke-width="3" x1="12" x2="26.0357" y1="1.5" y2="1.5"></line>
                            </svg>
                            <svg class="bin-bottom" fill="none" viewBox="0 0 33 39" xmlns="http://www.w3.org/2000/svg">
                                <mask id="path-1-inside-1_8_19" fill="white">
                                    <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
                                </mask>
                                <path d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                                      fill="white" mask="url(#path-1-inside-1_8_19)"></path>
                                <path d="M12 6L12 29" stroke="white" stroke-width="4"></path>
                                <path d="M21 6V29" stroke="white" stroke-width="4"></path>
                            </svg>
                        </button>
                    </div>


                </div><!--帖子头部-->
                <div style="margin: 30px auto 0 auto; text-align: left; font-size:15px; font-weight: bolder;  word-break: break-all;">
                    {{ con.title }}
                </div>
                <div class="min-container-rom" @click="saveId(con.id)" v-html="con.content">

                </div><!--帖子中间部分-->
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import {Message, MessageBox} from "element-ui";


export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Personalposts",
    data() {
        return {

            ID: '',
            dataArray: [], // 原始
            filteredArray: [], // 过滤后
        }
    },
    created() {
        document.title = "个人小窝";
        this.ID = localStorage.getItem('userId');
        this.getData();

    },


    methods: {
        gobtn(id) {
            MessageBox.confirm('确定要删除？Σ(っ°Д°;)っ', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const baseUrl = `http://${window.location.hostname}:9090`;
                axios.delete(`${baseUrl}/DeletePost?id=${id}`)
                Message({
                    type: 'success',
                    message: '删除成功! (꒪Д꒪)ノ '
                });
                location.reload();
            }).catch(() => {
                Message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },


        saveId(id) {
            localStorage.setItem('savedId', id);
            // this.$router.push('publish')
            const newWindow = window.open('/#/publish', '_blank');
            newWindow.focus();
        },
        getData() {
            const baseUrl = `http://${window.location.hostname}:9090`;
            axios.get(`${baseUrl}/post`)
                .then(response => {
                    this.dataArray = response.data;
                    this.filteredArray = this.dataArray.filter(item => item.topic_id === parseInt(this.ID)); // 过滤
                    this.filteredArray.forEach(item => {
                        item.content = this.formatContent(item.content);
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },
        // // 将 \n 转换为 <br> 标签
        formatContent(content) {
            return content.replace(/\n/g, '<br>');
        },

    }
}
</script>

<style scoped>
/*内容*/
.row-con {
    height: auto;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: rgba(240, 255, 255, 0.5);
}

.container-top {
    width: 100%;
    height: 50px;
}

.info {
    margin-left: 30px;
    text-align: left;
    font-size: 15px;
    overflow: hidden;
    float: left;
}

.container-rom {
    width: 800px;
    margin: 0 auto;
    padding: 20px 0;
    word-break: break-all;
    text-align: left;
    height: auto;
    font-size: 14px;
    font-weight: 200;
}

/*内容*/

/**/
.min-row-con {
    max-width: 992px;
    height: auto;
    padding: 0 10px 10px 10px  ;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: rgba(240, 255, 255, 0.5);
}

.min-container-top {
    width: 100%;
    height: 50px;
}

.min-info {
    margin-left: 30px;
    text-align: left;
    font-size: 15px;
    overflow: hidden;
    float: left;
}

.min-container-rom {
    width: auto;
    margin: 10px 0 ;
    text-align: justify;
    font-size: 10px;
    font-weight: 200;
    max-height: 66px;

    /* 在恰当的断字点进行换行 */
    word-break: break-all;
    /* 超出范围隐藏 */
    overflow: hidden;
    /* 文字超出用省略号 */
    text-overflow: ellipsis;
    /* 盒子模型 */
    display: -webkit-box;
    /* 显示的文本行数 */
    -webkit-line-clamp: 5;
    /* 子元素的垂直排列方式 */
    -webkit-box-orient: vertical;
}

/**/

.bin-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: transparent;
    cursor: pointer;
    border: none;
    transition-duration: 0.3s;
}

.bin-bottom {
    width: 15px;
}

.bin-top {
    width: 17px;
    transform-origin: right;
    transition-duration: 0.3s;
}

.bin-button:hover .bin-top {
    transform: rotate(45deg);
}

.bin-button:hover .bin-top, .bin-button:hover .bin-bottom {
    background-color: rgb(255, 0, 0);
}

.bin-button:active {
    transform: scale(0.9);
}


</style>