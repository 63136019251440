<template>
    <div>
        <el-carousel :interval="4000" class="imgrow-l hidden-sm-and-down">
            <el-carousel-item v-for="(image, index) in images" :key="index" class="carousel-item">
                <img :alt="'Image ' + (index + 1)" :src="image.img">
            </el-carousel-item>
        </el-carousel>

        <el-carousel :interval="4000" class="minimgrow-l hidden-md-and-up">
            <el-carousel-item v-for="(image, index) in images" :key="index" class="carousel-item">
            <img :alt="'Image ' + (index + 1)" :src="image.img">
        </el-carousel-item>
        </el-carousel>

    </div>
</template>

<script>
import axios from "axios";

const baseUrl = `http://${window.location.hostname}:9090`;
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Carousel",
    data() {
        return {
            images: [],
        }
    },
    mounted() {
        this.fetchImages();
    },
    methods: {
        async fetchImages() {
            try {
                const response = await axios.get(`${baseUrl}/Carousel`)
                this.images = response.data
            } catch (error) {
                console.error('', error)
            }
        }
    }
}
</script>

<style scoped>
.imgrow-l {
    position: relative;
    height: 350px;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* 确保轮播项铺满整个容器 */
}

.imgrow-l img {
    width: 100%;
    transition: all 0.6s;
    object-fit: contain; /* 保持图片比例并尽可能显示整个图片 */
}

.imgrow-l img:hover {
    transform: scale(1.1);
}

/deep/ .el-carousel__container {
    height: 100%;
}

.minimgrow-l {
    position: relative;
    height: 150px;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.minimgrow-l img {
    width: 100%;
    transition: all 0.6s;
}

.minimgrow-l img:hover {
    transform: scale(1.1);
}


</style>