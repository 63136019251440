<template>
    <div>
        <div class="container hidden-sm-and-down">
            <div class="login-wrapper">
                <div class="header">注册</div>
                <el-form ref="EnrollFormRef" :model="EnrollForm" :rules="rules" class="form-wrapper">

                    <el-form-item prop="username">
                        <el-input v-model="EnrollForm.username" class="input-item" name="username"
                                  placeholder="昵称"></el-input>
                    </el-form-item>

                    <el-form-item prop="uid">
                        <el-input v-model="EnrollForm.uid" class="input-item" name="uid"
                                  placeholder="账户"></el-input>
                    </el-form-item>

                    <el-form-item prop="password">
                        <el-input v-model="EnrollForm.password" class="input-item" name="password" placeholder="密码"
                                  show-password></el-input>
                    </el-form-item>

                    <el-form-item prop="gender">
                        <el-select v-model="EnrollForm.gender" class="input-item" name="gender" placeholder="性别"
                                   style="border-bottom: none;">
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                            <el-option label="保密" value="保密"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item prop="age">
                        <el-input v-model="EnrollForm.age" class="input-item" name="age" placeholder="年龄"></el-input>
                    </el-form-item>


                    <el-form-item prop="Captcha">
                        <el-input v-model="EnrollForm.Captcha" class="input-item" clearable
                                  placeholder="请输入验证码" style="width: 150px;float: left;"
                                  @input="isCodeCorrect=false"/>
                        <div style="float: right" @click="refreshCode">
                            <!--验证码组件-->
                            <s-identify :identifyCode="identifyCode"></s-identify>
                        </div>
                        <div v-if="isCodeCorrect" class="error_message">验证码错误</div>
                    </el-form-item>

                    <div style="height: 130px;">
                        <p style="width: 100%; text-align: left;font-size: 10px">选择头像</p>
                        <input style="float: left; width: 150px;" type="file" @change="toGetImg">
                        <img v-if="valueUrl" :src=valueUrl alt="" style="width: 100px;height: 100px; float: right">
                    </div>

                    <el-form-item>
                        <el-button class="btn" @click="register">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>

        </div>

        <div class="min-container hidden-md-and-up">
            <div class="min-login-wrapper">
                <div class="min-header">注册</div>
                <el-form ref="EnrollFormRef" :model="EnrollForm" :rules="rules" class="form-wrapper">

                    <el-form-item prop="username">
                        <el-input v-model="EnrollForm.username" class="min-input-item" name="username"
                                  placeholder="昵称"></el-input>
                    </el-form-item>

                    <el-form-item prop="uid">
                        <el-input v-model="EnrollForm.uid" class="min-input-item" name="uid"
                                  placeholder="账户"></el-input>
                    </el-form-item>

                    <el-form-item prop="password">
                        <el-input v-model="EnrollForm.password" class="min-input-item" name="password"
                                  placeholder="密码"
                                  show-password></el-input>
                    </el-form-item>

                    <el-form-item prop="gender">
                        <el-select v-model="EnrollForm.gender" class="min-input-item" name="gender" placeholder="性别"
                                   style="border-bottom: none;">
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                            <el-option label="保密" value="保密"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item prop="age">
                        <el-input v-model="EnrollForm.age" class="min-input-item" name="age"
                                  placeholder="年龄"></el-input>
                    </el-form-item>


                    <div style="height: 130px;">
                        <p style="width: 100%; text-align: left;font-size: 10px">选择头像</p>
                        <input style="float: left; width: 150px;" type="file" @change="toGetImg">
                        <img v-if="valueUrl" :src=valueUrl alt="" style="width: 100px;height: 100px; float: right">
                    </div>

                    <el-form-item>
                        <el-button class="min-btn" @click="submit">确定</el-button>
                    </el-form-item>

                    <Vcode
                        :show="isShow"
                        @close="close"
                        @fail='fail'
                        @success="success"
                        :canvasWidth = "300"
                        :imgs="[Img1, Img2,Img3,Img4,Img5]"
                    ></Vcode>

                </el-form>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import {MessageBox} from "element-ui";
import SIdentify from "@/components/SIdentify.vue"
import Vcode from "vue-puzzle-vcode";

export default {
    components: {
        Vcode
    },
    comments: {SIdentify},
    // eslint-disable-next-line vue/multi-word-component-names
    name: "enroll",
    data() {
        return {
            Img1:'/slider/1.jpg',
            Img2:'/slider/2.jpg',
            Img3:'/slider/3.jpg',
            Img4:'/slider/4.jpg',
            Img5:'/slider/5.jpg',
            isShow: false,
            isCodeCorrect: false,
            identifyCode: '',
            identifyCodes: ['0', '1', '2', '3', ...'a', 'b', 'c', ...'z'],//验证码出现的数字和字母
            valueUrl: '', // 用于显示上传的图片
            errorMessage: '',
            EnrollForm: {
                username: "",
                uid: "",
                password: "",
                age: "",
                Captcha: "",
                gender: '男',
            },
            rules: {
                username: [
                    {required: true, message: "请输入用户昵称:)", trigger: "blur"},
                    {min: 2, max: 11, message: "长度在2 - 11个字符以内", trigger: "blur",},
                ],
                uid: [
                    {required: true, message: "请输入账号:)", trigger: "blur"},
                    {min: 5, max: 11, message: "5 ~ 11位数字", trigger: "blur",},
                ],
                password: [
                    {required: true, message: "请输入密码~~", trigger: "blur"},
                    {min: 8, max: 8, message: "8个字符", trigger: "blur"},
                ],
                age: [
                    {required: true, message: "请输入年龄~~", trigger: "blur"},
                    {min: 2, max: 2, message: "长度在2字符", trigger: "blur"},
                ],
                Captcha: [
                    {required: true, message: "请输入验证码", trigger: "blur"}
                ],
            },
            file: null,
        }
    },
    methods: {
        // 更新验证码
        refreshCode() {
            this.identifyCode = ''
            this.makeCode(this.identifyCodes, 4)
        },
        makeCode(o, l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
            }
        },
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min);
        },

        async register() {
            const {username, uid, password, age, Captcha: captcha, gender} = this.EnrollForm;

            const trimmedUsername = username.trim();
            const trimmedUid = uid.trim();
            const trimmedPassword = password.trim();
            const trimmedAge = age.trim();
            const trimmedCaptcha = captcha.trim();

            const isValidUsername = trimmedUsername.length >= 2 && trimmedUsername.length <= 11;
            const isValidUid = trimmedUid.length >= 5 && trimmedUid.length <= 11;
            const isValidPasswordLength = trimmedPassword.length === 8;
            const isNumericAge = !isNaN(Number(trimmedAge));

            if (!isValidUsername || !isValidUid || !isValidPasswordLength || !isNumericAge) {
                return;
            }

            if (trimmedCaptcha.toLowerCase() !== this.identifyCode.toLowerCase()) {
                this.isCodeCorrect = true;
                return;
            }

            try {
                const formData = new FormData();
                formData.append('username', trimmedUsername);
                formData.append('uid', trimmedUid);
                formData.append('password', trimmedPassword);
                formData.append('age', trimmedAge);
                formData.append('gender', gender.trim());
                formData.append('file', this.file);

                const baseUrl = `http://${window.location.hostname}:9090`;
                const response = await axios.post(`${baseUrl}/upuser`, formData);

                if (response.data === 'OK') {
                    MessageBox.alert("注册成功，即将跳转到登录界面", "提示", {
                        type: "success",
                        duration: 500,
                        callback: () => {
                            this.$router.push("/login");
                        },
                    });
                }
            } catch (error) {
                if (error.response.status === 400) {
                    alert('账户已存在');
                } else {
                    console.error('An error occurred:', error);
                }
            }
        },

        submit(){
            const {username, uid, password, age} = this.EnrollForm;

            const trimmedUsername = username.trim();
            const trimmedUid = uid.trim();
            const trimmedPassword = password.trim();
            const trimmedAge = age.trim();


            const isValidUsername = trimmedUsername.length >= 2 && trimmedUsername.length <= 11;
            const isValidUid = trimmedUid.length >= 5 && trimmedUid.length <= 11;
            const isValidPasswordLength = trimmedPassword.length === 8;
            const isNumericAge = !isNaN(Number(trimmedAge));

            if (!isValidUsername || !isValidUid || !isValidPasswordLength || !isNumericAge) {
                alert("请将资料填写完整")
            return
            }
            this.isShow = true;
        },

        async success(){
            this.isShow = false; // 通过验证后，需要手动隐藏模态框
            const {username, uid, password, age, gender} = this.EnrollForm;

            const trimmedUsername = username.trim();
            const trimmedUid = uid.trim();
            const trimmedPassword = password.trim();
            const trimmedAge = age.trim();


            const isValidUsername = trimmedUsername.length >= 2 && trimmedUsername.length <= 11;
            const isValidUid = trimmedUid.length >= 5 && trimmedUid.length <= 11;
            const isValidPasswordLength = trimmedPassword.length === 8;
            const isNumericAge = !isNaN(Number(trimmedAge));

            if (!isValidUsername || !isValidUid || !isValidPasswordLength || !isNumericAge) {
                return;
            }

            try {
                const formData = new FormData();
                formData.append('username', trimmedUsername);
                formData.append('uid', trimmedUid);
                formData.append('password', trimmedPassword);
                formData.append('age', trimmedAge);
                formData.append('gender', gender.trim());
                formData.append('file', this.file);

                const baseUrl = `http://${window.location.hostname}:9090`;
                const response = await axios.post(`${baseUrl}/upuser`, formData);

                if (response.data === 'OK') {
                    MessageBox.alert("注册成功，即将跳转到登录界面", "提示", {
                        type: "success",
                        duration: 500,
                        callback: () => {
                            this.$router.push("/login");
                        },
                    });
                }
            } catch (error) {
                if (error.response.status === 400) {
                    alert('账户已存在');
                } else {
                    console.error('An error occurred:', error);
                }
            }

        },
        // 用户点击遮罩层，应该关闭模态框
        close(){
            this.isShow = false;
        },
        fail(){
            console.log('验证失败');
        },




        toGetImg(event) {
            this.file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.valueUrl = reader.result;
            };
            reader.readAsDataURL(this.file);
        },
    },
    // 初始化验证码
    mounted() {
        this.identifyCode = ''
        this.makeCode(this.identifyCode, 4)
    },
}
</script>

<style scoped>
.error_message {
    font-size: 12px;
    color: red;
}

.el-input /deep/ .el-input__inner {
    border: none;
}

.container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    padding: 20px 0;
    background-image: linear-gradient(to right, #fbc2eb, #a6c1ee);
    border-radius: 5px;
}

.login-wrapper {
    background-color: #fff;
    width: 358px;
    height: auto;
    border-radius: 15px;
    padding: 0 50px;
    margin: 0 auto;
}

.header {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    line-height: 200px;
}

.input-item {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 1px solid rgb(128, 125, 125);
    font-size: 15px;
    outline: none;
}

.input-item::placeholder {
    text-transform: uppercase;
}

.btn {
    text-align: center;
    padding: 10px;
    width: 100%;
    background-image: linear-gradient(to right, #a6c1ee, #fbc2eb);
    color: #fff;
    margin: 40px auto;
}

.msg {
    text-align: center;
    line-height: 88px;
}

a {
    text-decoration-line: none;
    color: #abc1ee;
}

.min-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    padding: 20px 0;
    background-image: linear-gradient(to right, #fbc2eb, #a6c1ee);
    border-radius: 5px;
}

.min-login-wrapper {
    background-color: #fff;
    width: 300px;
    height: auto;
    border-radius: 15px;
    padding: 0 10px;
    margin: 0 auto;
}

.min-header {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 80px;
}

.min-input-item {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 1px solid rgb(128, 125, 125);
    font-size: 12px;
    outline: none;
}

.min-input-item::placeholder {
    text-transform: uppercase;
}

.min-btn {
    text-align: center;
    padding: 10px;
    width: 100%;
    background-image: linear-gradient(to right, #a6c1ee, #fbc2eb);
    color: #fff;
    margin: 40px auto;
}


</style>