<template>
    <el-row :gutter="20">

        <bg-module/>  <!--背景组件-->
        <el-col :span="19" class="hidden-sm-and-down ">
            <Carousel/>

            <el-skeleton :count='counts' :loading="loading" animated style=" text-align: left;">
                <template slot="template">
                    <div class="row-l" style="padding: 10px 0">
                        <div class="container-top">
                            <el-skeleton-item
                                    style="border-radius: 50px; width: 50px;height: 50px; float: left;margin: 10px 0 0 25px;"
                                    variant="image"/>
                            <!--头像-->
                            <div class="info">
                                <el-skeleton-item style="width: 100px;margin-top: 10px;" variant="h4"/><!--昵称-->
                                <el-skeleton-item variant="h4"/><!--时间-->
                            </div>
                        </div><!--帖子头部-->
                        <el-skeleton-item style="width:50%;margin-top: 10px;" variant="h4"/>
                        <el-skeleton-item style="width:80%; margin-left: 30px" variant="h4"/><!--帖子内容部分-->
                        <el-skeleton-item style="width:80%; margin-left: 30px" variant="h4"/><!--帖子内容部分-->
                    </div>
                </template>
            </el-skeleton>


            <div v-for="product in displayedProducts" :key="product.id" class="row-l">
                <div class="container-top">
                    <img :src="product.user_img_data" alt=""
                         style="border-radius: 50px; width: 50px;height: 50px; float: left;margin: 10px 0 0 25px;">
                    <!--头像-->

                    <div class="info">
                        <el-popover
                                placement="right"
                                trigger="click"
                                width="200"
                                content="发表过{{}}条帖子,进入空间"
                        >
                            <p slot="reference" style="margin: 10px 0 0 0">
                                {{ product.user_name }}
                            </p>
                        </el-popover>

                        <p style="font-size: 10px;color: dimgray;">发表于 {{ product.formatted_time }} </p><!--时间-->
                    </div>

                    <div style="width:100px;height:20px;display: flex;justify-content: space-between; float: right;padding-top: 10px;">
                        <like :product="product"/>
                        <Delete v-if="isCurrentUserPost(product.topic_id)" :product="product"/>
                    </div>

                </div><!--帖子头部-->

                <div style="margin: 30px 0 0 100px">
                    <div style="font-size:14px; font-weight: bolder;  word-break: break-all;">
                        {{ product.title }}
                    </div>
                    <div class="container-rom" @click="saveId(product.id)" v-html="product.content"></div><!--帖子内容部分-->
                </div>
            </div><!--大屏-->

            <!-- 分页组件 -->
            <el-pagination
                    :current-page="currentPage"
                    :hide-on-single-page="value"
                    :page-count="Math.ceil(totalProducts / pageSize)"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper, total"
                    @current-change="handleCurrentChange"
            ></el-pagination>
        </el-col>

        <el-col class=" hidden-md-and-up">
            <Carousel/>
            <el-skeleton :count='counts' :loading="loading" animated style=" text-align: left;">
                <template slot="template">
                    <div class="min-row-l" style="padding: 10px 0">
                        <div class="container-top">
                            <el-skeleton-item
                                    style="border-radius: 50px; width: 50px;height: 50px; float: left;margin: 10px 0 0 25px;"
                                    variant="image"/>
                            <!--头像-->
                            <div class="info">
                                <el-skeleton-item style="width: 100px;margin-top: 10px;" variant="h4"/><!--昵称-->
                                <el-skeleton-item variant="h4"/><!--时间-->
                            </div>
                        </div><!--帖子头部-->
                        <el-skeleton-item style="width:50%;margin-top: 10px;" variant="h4"/>
                        <el-skeleton-item style="width:80%; margin-left: 30px" variant="h4"/><!--帖子内容部分-->
                        <el-skeleton-item style="width:80%; margin-left: 30px" variant="h4"/><!--帖子内容部分-->
                    </div>
                </template>
            </el-skeleton>

            <div v-for="product in displayedProducts" :key="product.id" class="min-row-l">
                <div class="min-container-top">
                    <img :src="product.user_img_data" alt=""
                         style="border-radius: 40px; width: 40px;height: 40px; float: left;margin: 10px 0 0 10px;">
                    <!--头像-->

                    <div class="min-info">
                        <p style="margin: 10px 0 0 0; font-size: 10px;">
                            {{ product.user_name }}
                        </p><!--昵称-->
                        <p style="font-size: 8px;color: dimgray;">发表于 {{ product.formatted_time }} </p><!--时间-->
                    </div>

                    <div style="width:60px;height:30px; float: right;padding-top: 5px;">
                        <like :product="product"/>
                        <Delete v-if="isCurrentUserPost(product.topic_id)" :product="product"/>
                    </div>

                </div><!--帖子头部-->

                <div style="margin: 20px 0 0 0px">
                    <div style="font-size:13px; font-weight: bolder;  word-break: break-all;">
                        {{ product.title }}
                    </div>
                    <div class="min-container-rom" @click="saveId(product.id)" v-html="product.content"></div>
                    <!--帖子内容部分-->
                </div>

            </div><!--小屏-->

            <!-- 分页组件 -->
            <el-pagination
                    :current-page="currentPage"
                    :hide-on-single-page="value"
                    :page-count="Math.ceil(totalProducts / pageSize)"
                    :page-size="pageSize"
                    background
                    layout="prev, pager, next, jumper, total"
                    small
                    @current-change="handleCurrentChange"
            ></el-pagination>
        </el-col>


        <el-col :span="4" class="hidden-sm-and-down">
            <div class="row-r">

                <weather-module/> <!--时间-->

                <div class="gg">
                    <h5>公告</h5>
                    <h5>禁止发表不当言论</h5>
                </div>


            </div>
        </el-col>
    </el-row>
</template>

<script>
import api from '../API/homepageApi';
import Like from "@/components/like.vue";
import Delete from "@/components/Delete.vue"
import Carousel from "@/components/Carousel.vue";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "homepage",
    components: {
        Carousel,
        Delete,
        Like,
    },
    data() {
        return {
            loading: true,
            productList: [],
            value: false,
            currentPage: 1, // 当前页码
            pageSize: 10, // 每页显示帖子数量
            userLovePosts: [],
            counts: 3,
        }
    },
    created() {
        document.title = "主页";
    },

    computed: {
        totalProducts() {
            return this.productList.length; // 获取帖子总数
        },
        displayedProducts() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = this.currentPage * this.pageSize;
            if (start >= this.productList.length) {
                return [];
            }
            return this.productList.slice(start, Math.min(end, this.productList.length));
        },
    },

    async mounted() {
        try {
            const productList = await api.fetchtableRow();
            this.counts = productList.length
            productList.sort((a, b) => a.id - b.id); //id排序
            this.productList = productList;
            this.loading = false;
        } catch (error) {
            console.error("获取数据失败", error);
        }
    },
    methods: {
        isCurrentUserPost(topic_id) {
            const ID = localStorage.getItem('userId');
            return parseInt(topic_id) === parseInt(ID)
        },

        saveId(id) {
            const token = localStorage.getItem('token')
            if (token) {
                localStorage.setItem('savedId', id);
                const newWindow = window.open('/#/publish', '_blank');
                newWindow.focus();
            } else {
                this.$router.push('login')
            }
        },
        handleCurrentChange(page) {
            this.currentPage = page; // 处理用户切换页面的方法
        },
    }
}
</script>

<style lang="css" scoped>
.gg {
    width: 200px;
    padding: 10px 0;
    margin: 20px 0;
    border-radius: 5px;
    background-color: rgba(255, 240, 245, 0.5);
}


/*内容*/


.row-l {
    position: relative;
    z-index: 10;
    padding: 0 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    text-align: left;
    background-color: rgba(240, 255, 255, 0.8);
    overflow: hidden;
}

.container-top {
    width: 100%;
    height: 50px;
}

.info {
    margin-left: 30px;
    text-align: left;
    font-size: 15px;
    overflow: hidden;
    float: left;

}

.container-rom {
    width: auto;
    margin: 15px 0 20px;
    font-size: 13px;
    text-align: justify;
    font-weight: 200;

    /* 在恰当的断字点进行换行 */
    word-break: break-all;
    /* 超出范围隐藏 */
    overflow: hidden;
    /* 文字超出用省略号 */
    text-overflow: ellipsis;
    /* 盒子模型 */
    display: -webkit-box;
    /* 显示的文本行数 */
    -webkit-line-clamp: 5;
    /* 子元素的垂直排列方式 */
    -webkit-box-orient: vertical;

}

/*内容*/
/*小内容*/
.min-row-l {
    position: relative;
    z-index: 10;
    padding: 0 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    text-align: left;
    background-color: rgba(240, 255, 255, 0.8);
    overflow: hidden;
}

.min-container-top {
    width: 100%;
    height: 50px;
}

.min-info {
    margin-left: 20px;
    text-align: left;
    font-size: 15px;
    overflow: hidden;
    float: left;

}

.min-container-rom {
    width: auto;
    margin: 10px 0;
    font-size: 10px;
    text-align: justify;
    font-weight: 200;
    max-height: 66px;

    /* 在恰当的断字点进行换行 */
    word-break: break-all;
    /* 超出范围隐藏 */
    overflow: hidden;
    /* 文字超出用省略号 */
    text-overflow: ellipsis;
    /* 盒子模型 */
    display: -webkit-box;
    /* 显示的文本行数 */
    -webkit-line-clamp: 5;
    /* 子元素的垂直排列方式 */
    -webkit-box-orient: vertical;
}


/*小内容*/


.row-r {
    width: 200px;
    /*padding: 10px 0 10px 0;*/
    padding: 10px 29px;
    border-radius: 5px;
    background-color: rgba(240, 255, 255, 0.5);
}

</style>