<template>
    <div class="loader">
        <div class="circle" :style="{ backgroundImage: getRandomImage }"></div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Loading",
    data() {
        return {
            images: [
                'url(/loadingimages/loading1.jpg)',
                'url(/loadingimages/loading2.jpg)',
                'url(/loadingimages/loading3.jpg)',
                'url(/loadingimages/loading4.jpg)',
                'url(/loadingimages/loading5.jpg)',
                'url(/loadingimages/loading6.jpg)',
            ],
        };
    },
    computed: {
        getRandomImage() {
            const randomIndex = Math.floor(Math.random() * this.images.length);
            return this.images[randomIndex];
        }
    }
}
</script>

<style scoped>
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Bungee Inline", cursive;
    background-color: white;
    z-index: 105;
}

.circle {
    width: 100px;
    height: 100px;
    position: relative;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    animation: fadeInOut 1.5s linear infinite;
}

.circle::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
</style>
