<template>
    <el-row justify="center" type="flex">
        <bg-module/>  <!--背景组件-->

        <el-col>

            <div style="height: 42px;margin:0 auto 30px;display: flex;justify-content: center;">
                <div class="input-container">
                    <input v-model="searchQuery" class="input" name="text" placeholder="输入关键词...按回车键搜索"
                           type="text" @keyup.enter="goto">
                    <span class="icon">
                           <svg fill="none" height="19px" viewBox="0 0 24 24" width="19px"
                                xmlns="http://www.w3.org/2000/svg" @click="goto">
                           <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier"
                                                                             stroke-linecap="round"
                                                                             stroke-linejoin="round"></g>
                           <g id="SVGRepo_iconCarrier"> <path d="M14 5H20" opacity="1" stroke="#000"
                                                              stroke-linecap="round" stroke-linejoin="round"
                                                              stroke-width="1.5"></path>
                           <path d="M14 8H17" opacity="1" stroke="#000" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="1.5"></path>
                           <path d="M21 11.5C21 16.75 16.75 21 11.5 21C6.25 21 2 16.75 2 11.5C2 6.25 6.25 2 11.5 2"
                                 stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"></path>
                           <path d="M22 22L20 20" opacity="1" stroke="#000" stroke-linecap="round"
                                 stroke-linejoin="round" stroke-width="3.5"></path> </g></svg></span>
                </div>
            </div>

            <div class="hidden-sm-and-down" style="min-height:500px;">
                <div v-if="d">
                    <el-empty :image-size="200" :description="description1"></el-empty>
                </div>
                <div v-else-if="dis">
                    <div v-for="product in filteredArray" :key="product.id" class="row-l">
                        <div class="container-top">
                            <img :src="product.img"
                                 alt=""
                                 style="border-radius: 50px; width: 50px;height: 50px; float: left;margin: 10px 0 0 10px;">
                            <!--头像-->
                            <div class="info">
                                <p style="margin: 10px 0 0 0">
                                    {{ product.username }}
                                </p><!--昵称-->
                                <p style="font-size: 10px;color: dimgray;">发表于 {{ product.created_at }} </p><!--时间-->
                            </div><!--昵称和时间-->
                        </div><!--帖子头部-->

                        <div style="margin: 30px auto 0 15px; font-size:14px; font-weight: bolder;  word-break: break-all;">
                            {{ product.title }}
                        </div>
                        <div class="container-rom" @click="saveId(product.id)">
                            {{ product.content }}
                        </div><!--帖子中间部分-->
                    </div>
                </div>
                <div v-else>
                    <el-empty :image-size="200" :description="description2"></el-empty>
                </div>
            </div>

            <div class="hidden-md-and-up" style="min-height:600px;">
                <div v-if="d">
                    <el-empty :image-size="100" :description="description1"></el-empty>
                </div>

                <div v-else-if="dis">
                <div v-for="product in filteredArray" :key="product.id" class="row-l" style="padding:10px">
                    <div class="container-top">
                        <img :src="product.img"
                             alt=""
                             style="border-radius: 50px; width: 50px;height: 50px; float: left;margin: 10px 0 0 10px;">
                        <!--头像-->
                        <div class="min-info">
                            <p style="margin: 10px 0 0 0">
                                {{ product.username }}
                            </p><!--昵称-->
                            <p style="font-size: 10px;color: dimgray;">发表于 {{ product.created_at }} </p><!--时间-->
                        </div><!--昵称和时间-->
                    </div><!--帖子头部-->

                    <div style="margin:20px auto 10px auto;font-size:14px; font-weight: bolder;  word-break: break-all;">
                        {{ product.title }}
                    </div>
                    <div class="min-container-rom" @click="saveId(product.id)">
                        {{ product.content }}
                    </div><!--帖子中间部分-->
                </div>
            </div>
                <div v-else>
                    <el-empty :image-size="100" :description="description2"></el-empty>
                </div>
            </div>
            <!-- 分页组件 -->

            <el-pagination
                    :current-page="currentPage"
                    :page-count="Math.ceil(totalProducts / pageSize)"
                    :page-size="pageSize"
                    background
                    layout="prev, pager, next, jumper, total"
                    small
                    @current-change="handleCurrentChange"
            ></el-pagination>
        </el-col>
    </el-row>
</template>

<script>
import axios from "axios";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "suosuo",
    data() {
        return {
            searchQuery: '', // 绑定输入框的值
            filteredArray: [],
            currentPage: 1, // 当前页码
            pageSize: 20, // 每页显示帖子数量
            dis:false,
            d:true,
            description1:"请搜索",
            description2:"无相关内容"
        }
    },
    computed: {
        totalProducts() {
            return this.filteredArray.length; // 获取帖子总数
        },
        displayedProducts() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = this.currentPage * this.pageSize;
            if (start >= this.filteredArray.length) {
                return [];
            }
            return this.filteredArray.slice(start, Math.min(end, this.filteredArray.length));
        },

    },
    methods: {
        // 搜索
        async goto() {
            const content = this.searchQuery.trim(); // 获取输入框的值作为搜索内容，并去除首尾空格
            if (content === "") {
                return;
            }
            try {
                const baseUrl = `http://${window.location.hostname}:9090`;
                const response = await axios.post(`${baseUrl}/sosuo`, {
                    content
                });
                if (response.data.error === '没有数据') {
                    this.d = false;
                    this.dis = false;
                } else {
                    this.filteredArray = response.data;
                    this.d = false;
                    this.dis = true;
                }
            } catch (error) {
                console.error('请求错误:', error);
            }
        },



        saveId(id) {
            const token = localStorage.getItem('token')
            if (token) {
                localStorage.setItem('savedId', id);
                console.log(token)
                console.log(id)
                // this.$router.push('publish')
                const newWindow = window.open('#/publish', '_blank');
                newWindow.focus();
            } else {
                this.$router.push('login')
            }
        },
        handleCurrentChange(page) {
            this.currentPage = page; // 处理用户切换页面的方法
        },
    }
}
</script>

<style scoped>

/deep/.el-empty__description p{
    color: black;
}

/* 在这里定义组件的样式 */
/*内容*/
.row-l {
    margin-bottom: 20px;
    border-radius: 5px;
    text-align: left;
    background-color: rgba(240, 255, 255, 0.5);
    overflow: hidden;
}

.container-top {
    width: 100%;
    height: 50px;
}

.info {
    margin-left: 100px;
    text-align: left;
    font-size: 15px;
    overflow: hidden;
}

.min-info {
    margin-left: 70px;
    text-align: left;
    font-size: 12px;
    overflow: hidden;
}

.container-rom {
    margin: 0 auto;
    padding: 15px;
    font-size: 13px;
    font-weight: 200;

    /* 在恰当的断字点进行换行 */
    word-break: break-all;
    /* 超出范围隐藏 */
    overflow: hidden;
    /* 文字超出用省略号 */
    text-overflow: ellipsis;
    /* 盒子模型 */
    display: -webkit-box;
    /* 显示的文本行数 */
    -webkit-line-clamp: 5;
    /* 子元素的垂直排列方式 */
    -webkit-box-orient: vertical;
}

.min-container-rom {
    width: auto;
    font-size: 10px;
    font-weight: 200;

    /* 在恰当的断字点进行换行 */
    word-break: break-all;
    /* 超出范围隐藏 */
    overflow: hidden;
    /* 文字超出用省略号 */
    text-overflow: ellipsis;
    /* 盒子模型 */
    display: -webkit-box;
    /* 显示的文本行数 */
    -webkit-line-clamp: 5;
    /* 子元素的垂直排列方式 */
    -webkit-box-orient: vertical;
}

/*内容*/

/*输入框*/

.input-container {
    width: 80%;
    float: left;
    position: relative;
    margin: 0 auto;
}

.icon {
    position: absolute;
    left: 12px;
    top: calc(50% + 7px);
    transform: translateY(calc(-50% - 5px));
}

.input {
    width: 100%;
    height: 30px;
    padding: 5px;
    border-radius: 20px;
    border: lightsteelblue solid 1px;
    font-size: 14px;
    text-indent: 2.5em;
}

.input-container:hover > .icon {
    animation: anim 1s linear infinite;
}

input:focus {
    outline: 2px solid #AFECAB;
}

/*输入框*/


</style>
