<template>
    <div id="app">
        <el-backtop></el-backtop>
        <!--        <music-module fixed/>-->
        <musicbox-module/>
        <el-container>

            <el-header class="hidden-sm-and-down max-header" style="height: 70px;">
                <navbar-text/>
                <div class="wz">
                    <div style="width: 100px;">
                        <router-link to='/homepage'>
                            <el-button style="font-size: 16px;" type="text">主页</el-button>
                        </router-link>
                    </div>
                    <div style="width: 100px;">
                        <router-link to='/suosuo'>
                            <el-button style="font-size: 16px;" type="text">搜索</el-button>
                        </router-link>
                    </div>
                    <div style="width: 100px;">
                        <el-button style="font-size: 16px;" type="text" @click="openGameSpace">异世界</el-button>
                    </div>
                </div>

                <div>

                </div>

                <div v-if="loggedIn" class="GY">
                    <img :src="userimg" alt="" style="border-radius: 50px; width: 50px;height: 50px;margin: 10px 0 0 0"
                         @click="drawer1 = true">
                </div>

                <div v-else class="GY">
                    <p>
                        <router-link to="/login">登录</router-link>
                    </p>
                    <p>
                        <router-link to="/enroll">注册</router-link>
                    </p>
                </div>
            </el-header><!--头 大屏-->


            <el-header class="hidden-md-and-up min-header" style="height: 50px;">
                <navbar-text style="float: left;width: 50px; margin-left:0;font-size: 18px;"/>
                <div v-if="loggedIn" style="height: 40px;">
                    <img :src="userimg" alt=""
                         style="border-radius: 30px; width: 30px;height: 30px;margin: 10px 0 0 0;float: right;"
                         @click="drawer2 = true">
                </div>

                <div v-else
                     style="width: 100px; height: 40px; display: flex;justify-content: space-between; font-size: 10px;">
                    <p style="width: 50px;margin:0; ">
                        <router-link to="/login">登录</router-link>
                    </p>
                    <p style="width: 50px;margin:0;">
                        <router-link to="/enroll">注册</router-link>
                    </p>
                </div>
            </el-header><!--头 小屏-->


            <el-drawer :visible.sync="drawer1" :with-header="false" size="400px">
                <div style=" width:400px;padding: 20px">
                    <div style="position:relative;display: flex;justify-content: center;width: 360px;height: 100px;margin-top: 20px">
                        <img :src="userimg" alt=""
                             style="border-radius: 100px; width: 100px;height: 100px; margin: 0 auto">
                    </div>
                    <div class="namebox">

                        <span v-if="number === 1 ">
                           管理员 您好
                        </span>

                        <span v-else-if="number === 2 ">
                            创世神 您好
                        </span>

                        <span v-else style="background:rgba(0,0,0,0);box-shadow: none;border: none">
                            用户 你好
                        </span>
                        <p style="">
                            {{ username }}
                        </p>
                    </div>

                    <div style="position:absolute;top: 20px;right: 15px ">
                        <Loginout/>
                    </div>

                    <div class="box-t"
                         style="width: 360px;height: 40px;border-radius: 15px;background-color: #F0FFFF; text-align: center;line-height: 40px;">
                        <router-link :to="{ name: 'personal'}">个人主页</router-link>
                    </div>

                    <div class="box-b">
                        <div class="box-f">
                            <p style="background-color: rgba(	135,206,250,0.9);" @click="boxpost">
                                帖子数 <br>
                                {{ postCount }}
                            </p>
                            <p style="background-color: rgba(65,105,225,0.9);">
                                评论数<br>
                                {{ commentCount }}
                            </p>
                            <p style="background-color: rgba(	255,182,193,0.9)" @click="boxlove">
                                喜欢<br>
                                {{ loveCount }}
                            </p>
                        </div>
                        <div class="box-text">
                            <router-link :to="{ path: '/compose', query: { username: 'username' } }">
                                <el-button type="text">编写</el-button>
                            </router-link>
                        </div>
                        <div v-if="number === 1 || number === 2 " class="box-text" style="margin-top: 20px;">
                            <router-link :to="{name:'UP'}" style="background-color:#66CDAA;">
                                <el-button type="text">管理员入口</el-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </el-drawer>

            <el-drawer :visible.sync="drawer2" :with-header="false" size="300px">
                <div style=" width:300px;padding: 10px">
                    <div style="position:relative;display: flex;justify-content: center;width: 280px;height: 50px;margin-top: 20px">
                        <img :src="userimg" alt=""
                             style="border-radius: 50px; width: 50px;height: 50px; margin: 0 auto">
                    </div>
                    <div class="min-namebox">

                        <span v-if="number === 1 ">
                           管理员 您好
                        </span>

                        <span v-else-if="number === 2 ">
                            创世神 您好
                        </span>

                        <span v-else style="background:rgba(0,0,0,0);box-shadow: none;border: none">
                            用户 你好
                        </span>
                        <p style="">
                            {{ username }}
                        </p>
                    </div>

                    <div style="position:absolute;top: 20px;right: 15px ">
                        <Loginout/>
                    </div>

                    <div class="box-t"
                         style="width: 280px;height: 40px;font-size:10px;border-radius: 15px;background-color: #F0FFFF; text-align: center;line-height: 40px;">
                        <router-link :to="{ name: 'personal'}">个人主页</router-link>
                    </div>

                    <div style="width: 280px;height: 300px;border-radius: 20px;">
                        <div class="min-box-f">
                            <p style="background-color: rgba(	135,206,250,0.9);" @click="boxpost">
                                帖子数 <br>
                                {{ postCount }}
                            </p>
                            <p style="background-color: rgba(65,105,225,0.9);">
                                评论数<br>
                                {{ commentCount }}
                            </p>
                            <p style="background-color: rgba(	255,182,193,0.9)" @click="boxlove">
                                喜欢<br>
                                {{ loveCount }}
                            </p>
                        </div>
                        <div class="min-box-text">
                            <router-link :to="{ path: '/compose', query: { username: 'username' } }">
                                <el-button style=" font-size: 10px;" type="text">编写</el-button>
                            </router-link>
                        </div>
                        <div v-if="number === 1 || number === 2 " class="min-box-text" style="margin-top: 20px; ">
                            <router-link :to="{name:'UP'}" style="background-color:#66CDAA;">
                                <el-button style=" font-size: 10px;" type="text">管理员入口</el-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </el-drawer>

            <el-main class="hidden-sm-and-down max-main">
                <router-view/>
            </el-main><!--大屏 内容-->

            <el-main class="hidden-md-and-up min-main">
                <router-view/>
            </el-main><!--小屏 内容-->

            <p class="BA">
                ICP备案号<br/>
                湘ICP备2024048731号-1<br>
                <span style="font-size: 10px">声明：本网站部分图片资源来自于《崩坏·星穹铁道》《蔚蓝档案》《原神》或其同人画师，如有侵权请联系管理员删除。</span>
            </p>


            <el-footer class="hidden-md-and-up min-footer" style="height: 40px">
                <div style="width: 100px;">
                    <router-link to='/homepage'>
                        <el-button style="font-size: 16px;" type="text">主页</el-button>
                    </router-link>
                </div>
                <div style="width: 100px;">
                    <router-link to='/suosuo'>
                        <el-button style="font-size: 16px;" type="text">搜索</el-button>
                    </router-link>
                </div>
                <div style="width: 100px;">
                    <el-button style="font-size: 16px;" type="text" @click="openGameSpace">异世界</el-button>
                </div>
            </el-footer><!--小屏显示-->

        </el-container>
    </div>
</template>

<script>
import axios from "axios";
import Loginout from "@/components/loginout.vue";
import 'element-ui/lib/theme-chalk/display.css';

const baseUrl = `http://${window.location.hostname}:9090`;
export default {
    name: 'app',
    components: {Loginout},
    data() {
        return {
            drawer1: false,
            drawer2: false,
            loggedIn: false,
            userimg: '',
            username: '',
            postCount: '',
            commentCount: '',
            loveCount: '',
            number: '', //管理员数字
        }
    },
    created() {
        this.checkLoggedInStatus();
    },
    watch: {
        drawer1(val) {
            if (val) {
                this.fetchData();
                this.getAdmin();
            } else {
                this.clearData();
            }
        },
        drawer2(val) {
            if (val) {
                this.fetchData();
                this.getAdmin();
            } else {
                this.clearData();
            }
        }
    },

    methods: {
        openGameSpace() {
            const url = this.$router.resolve({path: '/GameSpace'}).href;
            window.open(url, '_blank');
        },
        boxpost() {
            this.$router.push('/personal');
        },
        boxlove() {
            this.$router.push('/personal/collection')
        },
        // 抽屉盒子获取管理员信息
        async getAdmin() {
            const id = localStorage.getItem('userId');
            try {
                const response = await axios.post(`${baseUrl}/min`, {
                    id
                });
                this.number = response.data.permissions;
            } catch (error) {
                console.error('Error fetching data:', error);
                // 处理错误
            }
        },

        // 抽屉盒子获取数据
        async fetchData() {
            const id = localStorage.getItem('userId');
            try {
                const response = await axios.post(`${baseUrl}/drawer`, {
                    id
                });
                // 假设response.data包含所需的数据
                this.postCount = response.data.postCount;
                this.commentCount = response.data.commentCount;
                this.loveCount = response.data.loveCount;
            } catch (error) {
                console.error('Error fetching data:', error);
                // 处理错误
            }
        },
        clearData() {
            this.postCount = 0;
            this.commentCount = 0;
            this.loveCount = 0;
            this.number = null;
        },
        // 结尾
        checkLoggedInStatus() {
            const username = localStorage.getItem('username');
            const userimg = localStorage.getItem('userimg');
            // const id = localStorage.getItem('userId');
            if (username) {
                this.loggedIn = true;
                this.username = username;
                this.userimg = userimg;
            }
        },
        logout() {
            this.loggedIn = false;
            //退出清除.
            localStorage.removeItem('username');
            localStorage.removeItem('userId');
            localStorage.removeItem('token');
            localStorage.removeItem('userimg');
            localStorage.removeItem('permissions');

            if (this.$route.path !== '/homepage') {
                this.$router.push('/homepage');
            }

            location.reload();
        },
    },
}
</script>

<style scoped>
.BA {
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box; /* 将padding包含在宽度内 */
    width: 100%;
    line-height: 20px;
    margin: 0 auto 50px auto;
    background-color: rgba(13, 16, 33, 0.6);
    color: white;
    font-size: 12px;
    text-align: center;
}

.box-t a {
    color: #778899;
    text-decoration: none;
}

.box-b {
    width: 360px;
    height: 300px;
    /*background-color: #dddddd;*/
    border-radius: 15px;
    margin: 20px 0;
}

.box-f {
    width: 360px;
    display: flex;
    justify-content: space-around;
}

.min-box-f {
    width: 280px;
    display: flex;
    justify-content: space-around;
}

.min-box-f p {
    width: 80px;
    height: 50px;
    color: white;
    border-radius: 15px;
    line-height: 25px;
    text-align: center;
    font-size: 10px;
    background-color: #5b6c7d;
}

.box-f p {
    width: 100px;
    height: 50px;
    color: white;
    border-radius: 15px;
    line-height: 25px;
    text-align: center;
    background-color: #5b6c7d;
}

.box-text {
    width: 360px;
}

.box-text .el-button--text {
    color: white;
}

.box-text a {
    display: block;
    background-color: rgba(135, 206, 235, 0.9);
    text-align: center;
    line-height: 50px;
    width: 340px;
    height: 50px;
    margin: 0 auto;
    border-radius: 15px;
}

.min-box-text {
    width: 280px;
}

.min-box-text .el-button--text {
    color: white;
}

.min-box-text a {
    display: block;
    background-color: rgba(135, 206, 235, 0.9);
    text-align: center;
    line-height: 50px;
    width: 280px;
    height: 50px;
    margin: 0 auto;
    border-radius: 15px;
}

/deep/ .el-drawer.rtl {
    border-radius: 15px 0 0 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('assets/Wbg3.jpg');
    /*background-color:	#FFF0F5;*/
}


* {
    text-decoration: none;

}

.namebox {
    width: 360px;
    height: 20px;
    margin: 20px 0
}

.min-namebox {
    width: 280px;
    height: 20px;
    margin: 10px 0;

}

.min-namebox span {
    width: 80px;
    height: 100%;
    float: left;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    border-radius: 8px;
    background: linear-gradient(270deg, #03a9f4, #cc39a4, #ffb5d2);
    box-shadow: inset 0 0 60px 5px #ffffff;
    border: 0.1px solid #ADD8E6;
}

.min-namebox p {
    width: 120px;
    height: 100%;
    text-align: center;
    margin: 0;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.namebox span {
    width: 130px;
    height: 100%;
    float: left;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    border-radius: 10px;
    background: linear-gradient(270deg, #03a9f4, #cc39a4, #ffb5d2);
    box-shadow: inset 0 0 60px 5px #ffffff;
    border: 0.1px solid #ADD8E6;
}

.namebox p {
    width: 100px;
    height: 100%;
    text-align: center;
    margin: 0;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.max-header {
    width: 100%;
    min-width: 1200px;
    background-color: rgba(255, 240, 245, 0.9);
    border-radius: 10px;
    text-align: center;
    line-height: 70px;
    z-index: 10;
}

.min-header {
    width: 100%;
    max-width: 992px;
    background-color: rgba(255, 240, 245, 0.9);
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
}

.min-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    max-width: 992px;
    background-color: rgba(255, 240, 245, 1);
    z-index: 10;
    display: flex;
    justify-content: space-around;
}

.min-footer div {
    height: 40px;
    line-height: 40px;
}

.wz {
    float: left;
    width: auto;
    height: 70px;
    display: flex;
    justify-content: space-around;
}

.GY {
    float: right;
    width: 100px;
    height: 70px;
    margin-right: 150px;
    line-height: 70px;
    display: flex;
    justify-content: space-between;
}

.GY p {
    float: left;
    margin: 0;
}

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 12px;
}

.demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
}


.max-main {
    width: 100%;
    min-width: 1200px;
    margin: 10px auto;
    text-align: center;
    min-height: 1000px;
}

.min-main {
    width: 100%;
    max-width: 992px;
    min-height: 700px;
    margin: 10px 0;
    padding: 0;
    overflow: hidden;
}

/*管理员*/

/*管理员*/
</style>
