<template>
    <div>
        <div class="musicbox hidden-sm-and-down" @click="drawer1=true"></div>
        <div class="musicbox hidden-md-and-up" @click="drawer2=true"></div>

        <el-drawer :visible.sync="drawer1" :with-header="false" direction="ltr" size="550px">
            <vue-aplayer ref="aplayer1"
                         :list="musics"
                         :listFolded="false"
                         :music="musics[0]"
                         :mutex="true"
                         :showLrc="true"
                         fixed
            />
        </el-drawer>
        <el-drawer :visible.sync="drawer2" :with-header="false" direction="ltr" size="280px">
            <vue-aplayer ref="aplayer2"
                         :list="musics"
                         :listFolded="false"
                         :music="musics[0]"
                         :mutex="true"
                         :showLrc="true"
                         fixed
            />
        </el-drawer>


    </div>
</template>

<script>
import vueAplayer from "vue-aplayer";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "musicbox",
    components: {
        vueAplayer
    },
    data() {
        return {
            drawer1: false,
            drawer2: false,
            musics: [
                {
                    title: "轻涟 La vaguelette",
                    artist: "HOYO-MiX",
                    src: '/musics/轻涟.mp3',
                    pic: '/musicimages/轻涟.jpg',
                    lrc: '[00:00.00] 作词 : 哈尼 Hani/项柳 Hsiang Liu/三宝 Bao\n' +
                        '[00:01.00] 作曲 : 苑迪萌 Dimeng Yuan (HOYO-MiX)\n' +
                        '[00:02.33]作词 Lyricist：哈尼 Hani / 项柳 Hsiang Liu / 三宝 Bao\n' +
                        '[00:03.65]作曲 Composer：苑迪萌 Dimeng Yuan (HOYO-MiX)\n' +
                        '[00:04.98]编曲 Arranger：苑迪萌 Dimeng Yuan (HOYO-MiX)\n' +
                        '[00:06.05]演唱 Vocal Artist：Cécilia Cara\n' +
                        '[00:07.38]原声吉他 Acoustic Guitar：尤裴佳 Peijia You (HOYO-MiX)\n' +
                        '[00:09.24]电吉他 Electric Guitar：苑迪萌 Dimeng Yuan (HOYO-MiX)\n' +
                        '[00:10.31]合唱 Choir：唐卫青 Weiqing Tang / 何晓楠 Xiaonan He / 张怡 Yi Zhang / 柴锦斐 Jinfei Chai / 汤琳 Lin Tang / 陈瑞 Rui Chen / 喻佳星 Jiaxing Yu / 王碧华 Bihua Wang\n' +
                        '[00:11.37]乐队 Orchestra：龙之艺交响乐团 Art of Dragon Orchestra / Budapest Scoring Orchestra\n' +
                        '[00:12.70]录音棚 Recording Studio：上海广播大厦 Shanghai Media Group / Budapest Scoring\n' +
                        '[00:13.77]录音师 Recording Engineer：莫家伟 Jiawei Mo / Viktor Szabó\n' +
                        '[00:14.56]混音师 Mixing Engineer：李海 Hai Li\n' +
                        '[00:15.62]母带制作 Mastering Engineer：李海 Hai Li\n' +
                        '[00:19.20]出品 Produced by：HOYO-MiX\n' +
                        '[00:21.32]\n' +
                        '[00:21.32]\n' +
                        '[00:27.36]Ah, si je pouvais vivre dans l\'eau,\n' +
                        '[00:27.36]啊，若化水复回归途\n' +
                        '[00:27.89]le monde serait-il plus beau ?\n' +
                        '[00:27.89]令故土朝暮如初\n' +
                        '[00:32.70]Nous pardonneras-tu, ô chère mère ?\n' +
                        '[00:32.70]啊母亲可否将我宽恕\n' +
                        '[00:39.07]L\'eau dans son courant fait danser nos vies.\n' +
                        '[00:39.07]向四季流溯，引万籁驰骛\n' +
                        '[00:45.71]Et la cité, elle nourrit.\n' +
                        '[00:45.71]所过处百废复苏\n' +
                        '[00:50.49]Ainsi que toi, mon doux amour.\n' +
                        '[00:50.49]亦充盈挚爱的心湖\n' +
                        '[00:55.55]\n' +
                        '[00:55.55]\n' +
                        '[01:23.44]Non, le grand amour ne suffit pas.\n' +
                        '[01:23.44]爱，就将这酣梦深埋\n' +
                        '[01:30.35]Seul un adieu fleurira.\n' +
                        '[01:30.35]诀别如繁花盛开\n' +
                        '[01:35.66]C\'est notre histoire de vie, douce et amère.\n' +
                        '[01:35.66]似你我往怀，愉快悲哀\n' +
                        '[01:40.97]Moi, je suis et serai toujours là,\n' +
                        '[01:40.97]爱，我会在过去未来\n' +
                        '[01:46.55]à voir le monde et sa beauté.\n' +
                        '[01:46.55]注视这世间精彩\n' +
                        '[01:54.02]Et ça ne changera jamais, jamais...\n' +
                        '[01:54.02]一切从未改，未改……\n' +
                        '[02:24.52] 编曲 Arranger：苑迪萌 Dimeng Yuan (HOYO-MiX)\n' +
                        '[02:24.97] 演唱 Vocal Artist：Cécilia Cara\n' +
                        '[02:25.42] 原声吉他 Acoustic Guitar：尤裴佳 Peijia You (HOYO-MiX)\n' +
                        '[02:25.88] 电吉他 Electric Guitar：苑迪萌 Dimeng Yuan (HOYO-MiX)\n' +
                        '[02:26.33] 合唱 Choir：唐卫青 Weiqing Tang / 何晓楠 Xiaonan He / 张怡 Yi Zhang / 柴锦斐 Jinfei Chai / 汤琳 Lin Tang / 陈瑞 Rui Chen / 喻佳星 Jiaxing Yu / 王碧华 Bihua Wang\n' +
                        '[02:26.79] 乐队 Orchestra：龙之艺交响乐团 Art of Dragon Orchestra / Budapest Scoring Orchestra\n' +
                        '[02:27.24] 录音棚 Recording Studio：上海广播大厦 Shanghai Media Group / Budapest Scoring\n' +
                        '[02:27.69] 录音师 Recording Engineer：莫家伟 Jiawei Mo / Viktor Szabó\n' +
                        '[02:28.15] 混音师 Mixing Engineer：李海 Hai Li\n' +
                        '[02:28.60] 母带制作 Mastering Engineer：李海 Hai Li\n' +
                        '[02:29.06] 出品 Produced by：HOYO-MiX',
                    // title: "歌曲名称",
                    // artist: "演唱者",
                    // src/url: "歌曲文件的URL",
                    // pic: "封面图片URL",
                    // lrc: "歌词或歌词文件的URL",
                },
                {
                    title: "使一颗心免于哀伤",
                    artist: "HOYO-MiX",
                    src: '/musics/使一颗心免于哀伤.mp3',
                    pic: '/musicimages/使一颗心免于哀伤.jpg',
                    lrc: '[00:00.00] 作词 : 黑金雨\n' +
                        '[00:01.00] 作曲 : 王可鑫 Eli.W (HOYO-MiX)\n' +
                        '[00:02.00] 编曲 Arranger : 王可鑫 Eli.W (HOYO-MiX)\n' +
                        '[00:03.00] 制作人 Producer : 王可鑫 Eli.W (HOYO-MiX)\n' +
                        '[00:07.91]\n' +
                        '[00:10.29]\n' +
                        '[00:11.56]\n' +
                        '[00:13.09]Birds are born with no shackles\n' +
                        '[00:13.09]若鸟儿生来并无镣铐\n' +
                        '[00:18.69]Then what fetters my fate?\n' +
                        '[00:18.69]又是什么束缚我命运\n' +
                        '[00:24.96]Blown away, the white petals\n' +
                        '[00:24.96]洁白的花瓣被风卷去\n' +
                        '[00:30.15]Leave me trapped in the cage.\n' +
                        '[00:30.15]留我独自受困于囚笼\n' +
                        '[00:36.34]The endless isolation\n' +
                        '[00:36.34]孤独无止无休\n' +
                        '[00:39.52]Can\'t wear down my illusion\n' +
                        '[00:39.52]却难以磨灭我的想象\n' +
                        '[00:42.45]Someday, I’ll make a dream unchained\n' +
                        '[00:42.45]不如试着解开梦的枷锁吧\n' +
                        '[00:49.26]Let my heart bravely spread the wings\n' +
                        '[00:49.26]让我的心勇敢地振翅飞翔\n' +
                        '[00:53.28]Soaring past the night\n' +
                        '[00:53.28]穿过深沉的黑夜\n' +
                        '[00:56.16]To trace the bright moonlight\n' +
                        '[00:56.16]去追逐皎洁的月光\n' +
                        '[01:01.51]Let the clouds heal me of the stings\n' +
                        '[01:01.51]让云朵治愈往日的痛楚\n' +
                        '[01:05.34]Gently wipe the sorrow off my life\n' +
                        '[01:05.34]从生命中温柔地拭去忧伤\n' +
                        '[01:09.50]I dream\n' +
                        '[01:09.50]这是我的梦\n' +
                        '[01:13.41]\n' +
                        '[01:13.41]\n' +
                        '[01:18.50]What is meant by “miracle”,\n' +
                        '[01:18.50]那与我无关的词汇“奇迹”\n' +
                        '[01:24.68]A word outside my days?\n' +
                        '[01:24.68]究竟隐藏着怎样的意义？\n' +
                        '[01:30.98]Once again, repeat warbles\n' +
                        '[01:30.98]我又一次重复啼啭\n' +
                        '[01:36.16]But how could I escape?\n' +
                        '[01:36.16]但如何才能得到解脱？\n' +
                        '[01:42.83]No further hesitation\n' +
                        '[01:42.83]从此不再纠结于\n' +
                        '[01:45.64]On those unanswered questions\n' +
                        '[01:45.64]那些没有答案的问题\n' +
                        '[01:48.63]So now, I’ll make a dream unchained\n' +
                        '[01:48.63]现在我解开梦的枷锁\n' +
                        '[02:00.19]Let my heart bravely spread the wings\n' +
                        '[02:00.19]让我的心勇敢地振翅飞翔\n' +
                        '[02:05.39]Soaring past the night\n' +
                        '[02:05.39]穿过深沉的黑夜\n' +
                        '[02:08.02]To trace the bright moonlight\n' +
                        '[02:08.02]去追逐皎洁的月光\n' +
                        '[02:13.32]Let the clouds heal me of the stings\n' +
                        '[02:13.32]让云朵治愈往日的痛楚\n' +
                        '[02:17.20]Gently wipe the sorrow off my life\n' +
                        '[02:17.20]从生命中温柔地拭去忧伤\n' +
                        '[02:21.72]I dream\n' +
                        '[02:21.72]这是我的梦\n' +
                        '[02:37.37]Let my heart bravely spread the wings\n' +
                        '[02:37.37]让我的心勇敢地振翅飞翔\n' +
                        '[02:41.11]Soaring past the night\n' +
                        '[02:41.11]穿过深沉的黑夜\n' +
                        '[02:44.04]To trace the bright moonlight\n' +
                        '[02:44.04]去追逐皎洁的月光\n' +
                        '[02:49.28]Let the clouds heal me of the stings\n' +
                        '[02:49.28]让云朵治愈往日的痛楚\n' +
                        '[02:53.31]Gently wipe the sorrow off my life\n' +
                        '[02:53.31]从生命中温柔地拭去忧伤\n' +
                        '[03:00.08]I dream\n' +
                        '[03:00.08]这是我的梦\n' +
                        '[03:06.13]I dream\n' +
                        '[03:06.13]这是我的梦\n' +
                        '[03:09.92]\n' +
                        '[03:09.92]\n' +
                        '[03:11.36]\n' +
                        '[03:11.86]\n' +
                        '[03:12.42]\n' +
                        '[03:12.83]\n' +
                        '[03:13.24]\n' +
                        '[03:13.67]\n' +
                        '[03:14.06]\n' +
                        '[03:14.53]\n' +
                        '[03:14.95]\n' +
                        '[03:15.41]\n' +
                        '[03:15.74]\n' +
                        '[03:16.30]\n' +
                        '[03:17.16] 人声 Vocal Artist：Chevy\n' +
                        '[03:17.57] 木吉他 Acoustic Guitar：赵新博\n' +
                        '[03:17.99] 电吉他 Electric Guitar：赵新博\n' +
                        '[03:18.41] 架子鼓 Drums：眭逸凡Patrick\n' +
                        '[03:18.82] 乐队 Orchestra：国际首席爱乐乐团 International Master Philharmonic Orchestra\n' +
                        '[03:19.24] 人声录音棚 Vocal Recording Studio：The Hideout Recording Studio\n' +
                        '[03:19.65] 人声录音师 Vocal Recording Engineer：Luis Pacheco\n' +
                        '[03:20.07] 乐器录音棚 Instrumental Recording Studio：升赫录音棚Soundhub Studio/九紫天诚录音棚 SKY FIRE STUDIO\n' +
                        '[03:20.49] 乐器录音师 Instrumental Recording Engineer：Kevin刘瀚文@Soundhub Studios/董方昱\n' +
                        '[03:20.90] 混音师 Mixing Engineer：宫奇Gon (HOYO-MiX)\n' +
                        '[03:21.32] 母带制作 Mastering Engineer：宫奇Gon (HOYO-MiX)\n' +
                        '[03:21.73] 出品 Produced by：HOYO-MiX\n',
                },
                {
                    title: "情歌",
                    artist: "梁静茹",
                    src: '/musics/情歌.mp3',
                    pic: '/musicimages/情歌.jpg',
                    lrc: '[00:00.00] 作词 : 陈没\n' +
                        '[00:01.00] 作曲 : 伍冠谚\n' +
                        '[00:02.00] 编曲 : 陈建骐\n' +
                        '[00:25.86]时光是琥珀\n' +
                        '[00:28.62]泪一滴滴 被反锁\n' +
                        '[00:32.40]情书再不朽\n' +
                        '[00:35.40]也磨成沙漏\n' +
                        '[00:38.95]青春的上游\n' +
                        '[00:42.15]白云飞走\n' +
                        '[00:44.30]苍狗与海鸥\n' +
                        '[00:46.45]闪过的念头\n' +
                        '[00:49.82]潺潺的溜走\n' +
                        '[00:52.83]\n' +
                        '[00:53.17]命运好幽默\n' +
                        '[00:55.89]让爱的人 都沉默\n' +
                        '[00:59.76]一整个宇宙\n' +
                        '[01:02.72]换一颗红豆\n' +
                        '[01:06.51]回忆如困兽\n' +
                        '[01:09.58]寂寞太久\n' +
                        '[01:11.35]而渐渐温柔\n' +
                        '[01:13.72]放开了拳头\n' +
                        '[01:17.24]反而更自由\n' +
                        '[01:20.14]\n' +
                        '[01:20.43]慢动作 缱绻胶卷\n' +
                        '[01:23.36]重播默片\n' +
                        '[01:25.10]定格一瞬间\n' +
                        '[01:27.65]我们在告别的演唱会\n' +
                        '[01:31.91]说好不再见\n' +
                        '[01:34.39]\n' +
                        '[01:34.67]你写给我\n' +
                        '[01:37.16]我的第一首歌\n' +
                        '[01:40.53]你和我 十指紧扣\n' +
                        '[01:43.53]默写前奏\n' +
                        '[01:45.57]可是那然后呢\n' +
                        '[01:47.94]\n' +
                        '[01:48.34]还好我有\n' +
                        '[01:50.81]我这一首情歌\n' +
                        '[01:54.22]轻轻的 轻轻哼着\n' +
                        '[01:57.22]哭着笑着\n' +
                        '[01:58.95]我的 天长地久\n' +
                        '[02:04.97]\n' +
                        '[02:19.18]\n' +
                        '[02:20.46]命运好幽默\n' +
                        '[02:23.41]让爱的人都沉默\n' +
                        '[02:27.34]一整个宇宙\n' +
                        '[02:30.17]换一颗红豆\n' +
                        '[02:33.87]\n' +
                        '[02:34.40]回忆如困兽\n' +
                        '[02:37.79]寂寞太久\n' +
                        '[02:38.82]而渐渐温柔\n' +
                        '[02:41.31]放开了拳头\n' +
                        '[02:44.56]反而更自由\n' +
                        '[02:47.37]\n' +
                        '[02:48.34]长镜头 越拉越远\n' +
                        '[02:51.34]越来越远\n' +
                        '[02:53.09]事隔好几年\n' +
                        '[02:55.57]我们在怀念的演唱会\n' +
                        '[02:59.85]礼貌的吻别\n' +
                        '[03:03.90]\n' +
                        '[03:04.43]你写给我\n' +
                        '[03:06.78]我的第一首歌\n' +
                        '[03:10.32]你和我十指紧扣\n' +
                        '[03:13.25]默写前奏\n' +
                        '[03:15.19]可是那然后呢\n' +
                        '[03:17.72]\n' +
                        '[03:18.07]还好我有\n' +
                        '[03:20.71]我这一首情歌\n' +
                        '[03:24.03]轻轻的 轻轻哼着\n' +
                        '[03:27.00]哭着笑着\n' +
                        '[03:28.72]我的 天长地久\n' +
                        '[03:34.81]\n' +
                        '[03:35.22]陪我唱歌\n' +
                        '[03:37.78]清唱你的情歌\n' +
                        '[03:41.15]舍不得 短短副歌\n' +
                        '[03:44.09]心还热着\n' +
                        '[03:45.94]也该告一段落\n' +
                        '[03:48.89]\n' +
                        '[03:49.27]还好我有\n' +
                        '[03:51.71]我下一首情歌\n' +
                        '[03:55.13]生命宛如 静静的\n' +
                        '[03:58.14]相拥的河\n' +
                        '[03:59.89]永远 天长地久\n' +
                        '[04:07.24]\n',
                },
                {
                    title: "未闻花名",
                    artist: "茅野爱衣,户松遥,早见沙织",
                    src: '/musics/君がくれたもの.mp3',
                    pic: '/musicimages/未闻花名.jpg',
                    lrc: '[00:00.00] 作词 : 町田紀彦\n' +
                        '[00:00.07] 作曲 : 町田紀彦\n' +
                        '[00:00.15] 编曲 : とくP\n' +
                        '[00:00.23]君と夏の終わり 将来の夢\n' +
                        '[00:00.23]与你在夏末约定  将来的梦想\n' +
                        '[00:04.17]大きな希望 忘れない\n' +
                        '[00:04.17]远大的希望　别忘记\n' +
                        '[00:07.17]10年後の8月\n' +
                        '[00:07.17]十年后的八月\n' +
                        '[00:09.70]また出会えるのを 信じて\n' +
                        '[00:09.70]我相信我们还能再相遇\n' +
                        '[00:14.36]共创最美好的回忆...\n' +
                        '[00:14.36]最高の思い出を…\n' +
                        '[00:22.15]\n' +
                        '[00:39.96]出会いは ふっとした 瞬間 帰り道の交差点で\n' +
                        '[00:39.96]相识　是在那么不经意的瞬间　我在回家途中的十字路口\n' +
                        '[00:47.05]声をかけてくれたね 「一緒に帰ろう」\n' +
                        '[00:47.05]听见你的一声『一起回家吧』\n' +
                        '[00:54.31]僕は 照れくさそうに カバンで顔を隠しながら\n' +
                        '[00:54.31]我当时有点害羞   还拿书包遮住脸\n' +
                        '[01:01.47]本当は とても とても 嬉しかったよ\n' +
                        '[01:01.47]其实我心里好高兴　真的好高兴\n' +
                        '[01:07.24]\n' +
                        '[01:08.59]あぁ 花火が夜空 きれいに咲いて ちょっとセツナク\n' +
                        '[01:08.59]啊　烟火在夜空中灿烂盛开　几许伤感\n' +
                        '[01:15.77]あぁ 風が時間とともに 流れる\n' +
                        '[01:15.77]啊 风和时间一起飘过\n' +
                        '[01:21.84]\n' +
                        '[01:22.58]很高兴 很愉快   曾到处冒险\n' +
                        '[01:22.58]嬉しくって 楽しくって 冒険も いろいろしたね\n' +
                        '[01:29.76]二人の 秘密の 基地の中\n' +
                        '[01:29.76]就在我们的秘密基地中\n' +
                        '[01:36.12]\n' +
                        '[01:36.74]君と夏の終わり 将来の夢 大きな希望 忘れない\n' +
                        '[01:36.74]与你在夏末约定　将来的梦想    远大的希望　别忘记\n' +
                        '[01:43.92]10年後の8月 また出会えるのを 信じて\n' +
                        '[01:43.92]我相信十年后的八月　我们还能再相遇\n' +
                        '[01:51.01]君が最後まで 心から 「ありがとう」叫んでいたこと\n' +
                        '[01:51.01]你最后一直在心底呼喊着『谢谢你』\n' +
                        '[01:56.93]我是知道的\n' +
                        '[01:56.93]知っていたよ\n' +
                        '[01:58.20]很难过呢   强忍着泪水笑着说再见\n' +
                        '[01:58.20]涙をこらえて 笑顔でさようなら せつないよね\n' +
                        '[02:05.42]那一段最美好的回忆\n' +
                        '[02:05.42]最高の思い出を…\n' +
                        '[02:12.39]\n' +
                        '[02:13.07]あぁ 夏休みも あと少しで 終わっちゃうから\n' +
                        '[02:13.07]啊　暑假就快要过完了\n' +
                        '[02:20.31]あぁ 太陽と月 仲良くして\n' +
                        '[02:20.31]啊　太阳和月亮　默契十足\n' +
                        '[02:26.40]\n' +
                        '[02:27.08]悲しくって 寂しくって 喧嘩も いろいろしたね\n' +
                        '[02:27.08]想来令人悲伤　或许有些寂寥  我们也多有争吵\n' +
                        '[02:34.16]二人の 秘密の 基地の中\n' +
                        '[02:34.16]就在我们的秘密基地中\n' +
                        '[02:40.41]\n' +
                        '[02:41.14]君が最後まで 心から 「ありがとう」叫んでいたこと\n' +
                        '[02:41.14]你最后一直在心底呼喊着『谢谢你』\n' +
                        '[02:47.00]我是知道的\n' +
                        '[02:47.00]知っていたよ\n' +
                        '[02:48.34]很难过呢   强忍着泪水笑着说再见\n' +
                        '[02:48.34]涙をこらえて 笑顔でさようなら せつないよね\n' +
                        '[02:55.63]那一段最美好的回忆\n' +
                        '[02:55.63]最高の思い出を…\n' +
                        '[03:02.61]\n' +
                        '[03:03.32]你突然要转学　你我都无可奈何\n' +
                        '[03:03.32]突然の 転校で どうしようもなく\n' +
                        '[03:24.19]手紙 書くよ 電話もするよ 忘れないでね 僕のことを\n' +
                        '[03:24.19]我会写信给你　也会打电话给你  千万别忘记我\n' +
                        '[03:31.42]いつまでも 二人の 基地の中\n' +
                        '[03:31.42]无论什么时候   那段在秘密基地中的日子\n' +
                        '[03:37.85]\n' +
                        '[03:38.52]君と夏の終わり ずっと話して\n' +
                        '[03:38.52]与你在夏末　聊了那么多\n' +
                        '[03:42.53]从黄昏到繁星点点\n' +
                        '[03:42.53]夕日を見てから星を眺め\n' +
                        '[03:45.67]君の頬を 流れた涙は ずっと忘れない\n' +
                        '[03:45.67]泪水流过你的双颊　我永远不会忘记\n' +
                        '[03:52.82]君が最後まで 大きく手を振ってくれたこと\n' +
                        '[03:52.82]直到最后　你不停的挥手告别　这感觉也将常在我心中\n' +
                        '[03:58.54]きっと忘れない\n' +
                        '[03:58.54]一定不会忘记的\n' +
                        '[03:59.93]だから こうして 夢の中で ずっと永遠に…\n' +
                        '[03:59.93]就这样　让我们永远在梦中相会吧\n' +
                        '[04:06.40]\n' +
                        '[04:07.11]君と夏の終わり 将来の夢 大きな希望 忘れない\n' +
                        '[04:07.11]与你在夏末约定　将来的梦想   远大的希望　别忘记\n' +
                        '[04:14.27]10年後の8月 また出会えるのを 信じて\n' +
                        '[04:14.27]我相信十年后的八月　我们还能再相遇\n' +
                        '[04:21.40]君が最後まで 心から 「ありがとう」叫んでいたこと\n' +
                        '[04:21.40]你最后一直在心底呼喊着『谢谢你』\n' +
                        '[04:27.26]我是知道的\n' +
                        '[04:27.26]知っていたよ\n' +
                        '[04:28.64]很难过呢   强忍着泪水笑着说再见\n' +
                        '[04:28.64]涙をこらえて 笑顔でさようなら せつないよね\n' +
                        '[04:35.91]那一段最美好的回忆\n' +
                        '[04:35.91]最高の思い出を…\n' +
                        '[04:42.45]\n' +
                        '[04:43.16]那一段最美好的回忆\n' +
                        '[04:43.16]最高の思い出を…\n' +
                        '[04:53.01]\n' +
                        '[by:sSkirt]\n',
                },
                {
                    title: "Call of Silence ",
                    artist: "泽野弘之",
                    src: '/musics/Call of Silence.mp3',
                    pic: '/musicimages/1.jpg',
                    lrc: '[00:00.00] 编曲 : Clear Sky\n' +
                        '[00:30.05]Don\'t you think about me enough?\n' +
                        '[00:30.05]你对我的了解还不够吗？\n' +
                        '[00:36.98]I\'ve been burning my heart out\n' +
                        '[00:36.98]我的心一直在为你燃烧\n' +
                        '[00:43.64]Got to face need to tell you\n' +
                        '[00:43.64]我必须得面对 我要告诉你\n' +
                        '[00:50.56]I won\'t run cause I\'m reticent\n' +
                        '[00:50.56]我不会逃避 因为我本就沉默寡言\n' +
                        '[01:00.69]You will know you\'re reborn tonight\n' +
                        '[01:00.69]你会明白 今晚你将重获新生\n' +
                        '[01:07.61]Must be ragged but I\'ll stay by your side\n' +
                        '[01:07.61]即便衣衫褴褛 我依然会陪在你身边\n' +
                        '[01:14.55]Even if my body\'s bleached to the bones\n' +
                        '[01:14.55]就算我的身体变成白骨\n' +
                        '[01:21.49]I don\'t want go through that ever again\n' +
                        '[01:21.49]我也不愿再次经历那样的伤痛了\n' +
                        '[01:30.02]You will know you\'re reborn tonight\n' +
                        '[01:30.02]你会明白 今晚你将重获新生\n' +
                        '[01:36.97]Must be ragged but I\'ll stay by your side\n' +
                        '[01:36.97]即便衣衫褴褛 我依然会陪在你身边\n' +
                        '[01:43.63]Even if my body\'s bleached to the bones\n' +
                        '[01:43.63]就算我的身体变成白骨\n' +
                        '[01:50.58]I don\'t want go through that ever again\n' +
                        '[01:50.58]我也不愿再次经历那样的伤痛了\n' +
                        '[02:38.67]Don\'t you think about me enough?\n' +
                        '[02:38.67]你对我的了解还不够吗？\n' +
                        '[02:45.36]I\'ve been burning my heart out\n' +
                        '[02:45.36]我的心一直在为你燃烧\n' +
                        '[02:52.30]Got to face need to tell you\n' +
                        '[02:52.30]我必须得面对 我要告诉你\n' +
                        '[02:59.23]I won\'t run cause I\'m reticent\n' +
                        '[02:59.23]我不会逃避 因为我本就沉默寡言\n' +
                        '[03:09.38]You will know you\'re reborn tonight\n' +
                        '[03:09.38]你会明白 今晚你将重获新生\n' +
                        '[03:16.31]Must be ragged but I\'ll stay by your side\n' +
                        '[03:16.31]即便衣衫褴褛 我依然会陪在你身边\n' +
                        '[03:23.23]Even if my body\'s bleached to the bones\n' +
                        '[03:23.23]就算我的身体变成白骨\n' +
                        '[03:29.93]I don\'t want go through that ever again\n' +
                        '[03:29.93]我也不愿再次经历那样的伤痛了\n' +
                        '[03:38.72]You will know you\'re reborn tonight\n' +
                        '[03:38.72]你会明白 今晚你将重获新生\n' +
                        '[03:45.40]Must be ragged but I\'ll stay by your side\n' +
                        '[03:45.40]即便衣衫褴褛 我依然会陪在你身边\n' +
                        '[03:52.31]Even if my body\'s bleached to the bones\n' +
                        '[03:52.31]就算我的身体变成白骨\n' +
                        '[03:59.25]I don\'t want go through that ever again\n' +
                        '[03:59.25]我也不愿再次经历那样的伤痛了\n' +
                        '[by:心心念丶碎碎念]\n',
                },
                {
                    title: "One Last Kiss",
                    artist: "宇多田ヒカル",
                    src: '/musics/One Last Kiss.mp3',
                    pic: '/musicimages/2.jpg',
                    lrc: '[00:00.00] 作词 : 宇多田ヒカル\n' +
                        '[00:01.00] 作曲 : 宇多田ヒカル/A. G. Cook\n' +
                        '[00:02.00] 编曲 : 宇多田ヒカル/A. G. Cook\n' +
                        '[00:03.00] 制作人 : 宇多田ヒカル/A. G. Cook\n' +
                        '[00:18.61]\n' +
                        '[00:18.61]\n' +
                        '[00:20.54]初めてのルーブルは\n' +
                        '[00:20.54]第一次去卢浮宫时\n' +
                        '[00:22.55]なんてことはなかったわ\n' +
                        '[00:22.55]并没有什么特别的感觉\n' +
                        '[00:24.76]私だけのモナリザ\n' +
                        '[00:24.76]因为独属于我的蒙娜丽莎\n' +
                        '[00:26.71]もうとっくに出会ってたから\n' +
                        '[00:26.71]我早已遇见\n' +
                        '[00:29.12]初めてあなたを見た\n' +
                        '[00:29.12]初次遇见你的那天\n' +
                        '[00:30.97]あの日動き出した歯車\n' +
                        '[00:30.97]齿轮开始转动\n' +
                        '[00:33.32]无法停止那将要失去什么的预感\n' +
                        '[00:33.32]止められない喪失の予感\n' +
                        '[00:37.25]\n' +
                        '[00:37.25]\n' +
                        '[00:37.72]もういっぱいあるけど\n' +
                        '[00:37.72]虽说已经有很多次了\n' +
                        '[00:43.37]もう一つ増やしましょう\n' +
                        '[00:43.37]让我们再来一个吻吧\n' +
                        '[00:47.70]\n' +
                        '[00:47.70](Can you give me one last kiss？)\n' +
                        '[00:51.97]不想遗忘之事\n' +
                        '[00:51.97]忘れたくないこと\n' +
                        '[00:54.40]\n' +
                        '[00:54.40]\n' +
                        '[00:54.80]\n' +
                        '[00:54.80]Oh oh oh oh oh…\n' +
                        '[01:00.57]不愿遗忘之事\n' +
                        '[01:00.57]忘れたくないこと\n' +
                        '[01:03.35]\n' +
                        '[01:03.35]Oh oh oh oh oh…\n' +
                        '[01:09.18]\n' +
                        '[01:09.18]I love you more than you\'ll ever know\n' +
                        '[01:12.59]\n' +
                        '[01:12.59]\n' +
                        '[01:20.49]"我不擅长拍照"\n' +
                        '[01:20.49]「写真は苦手なんだ」\n' +
                        '[01:22.38]でもそんなものはいらないわ\n' +
                        '[01:22.38]但那种事情其实无关紧要\n' +
                        '[01:24.74]あなたが焼きついたまま\n' +
                        '[01:24.74]你的身影已经烙在\n' +
                        '[01:26.67]私の心のプロジェクター\n' +
                        '[01:26.67]我心中的放映机里\n' +
                        '[01:28.99]都假装着自己并不孤独\n' +
                        '[01:28.99]寂しくないふりしてた\n' +
                        '[01:30.81]まあ、そんなのお互い様か\n' +
                        '[01:30.81]嘛，我们就是那样\n' +
                        '[01:33.24]如果谁去找了对方\n' +
                        '[01:33.24]誰かを求めることは\n' +
                        '[01:35.10]即ち傷つくことだった\n' +
                        '[01:35.10]就会徒增伤痕\n' +
                        '[01:37.38]\n' +
                        '[01:37.38]\n' +
                        '[01:37.79]\n' +
                        '[01:37.79]Oh, can you give me one last kiss？\n' +
                        '[01:43.36]来一个火热的吻吧\n' +
                        '[01:43.36]燃えるようなキスをしよう\n' +
                        '[01:47.61]火热到即使想忘\n' +
                        '[01:47.61]忘れたくても\n' +
                        '[01:51.92]忘れられないほど\n' +
                        '[01:51.92]也难以忘怀\n' +
                        '[01:54.37]\n' +
                        '[01:54.37]\n' +
                        '[01:54.80]\n' +
                        '[01:54.80]Oh oh oh oh oh…\n' +
                        '[02:00.64]\n' +
                        '[02:00.64]I love you more than you\'ll ever know\n' +
                        '[02:03.27]\n' +
                        '[02:03.27]Oh oh oh oh oh…\n' +
                        '[02:09.16]\n' +
                        '[02:09.16]I love you more than you\'ll ever know\n' +
                        '[02:12.39]\n' +
                        '[02:12.39]\n' +
                        '[02:29.06]もう分かっているよ\n' +
                        '[02:29.06]我已经明白了\n' +
                        '[02:34.76]この世の終わりでも\n' +
                        '[02:34.76]就算这个世界走向终结\n' +
                        '[02:39.11]就算年龄渐长\n' +
                        '[02:39.11]年をとっても\n' +
                        '[02:45.70]你也是我无法忘记的人\n' +
                        '[02:45.70]忘れられない人\n' +
                        '[02:47.61]\n' +
                        '[02:47.61]\n' +
                        '[02:47.96]\n' +
                        '[02:47.96]Oh oh oh oh oh…\n' +
                        '[02:49.95]忘れられない人\n' +
                        '[02:49.95]无法忘记的人\n' +
                        '[02:58.99]\n' +
                        '[02:58.99]Oh oh oh oh oh…\n' +
                        '[03:00.80]\n' +
                        '[03:00.80]I love you more than you\'ll ever know\n' +
                        '[03:03.76]\n' +
                        '[03:03.76]Oh oh oh oh oh…\n' +
                        '[03:09.24]忘れられない人\n' +
                        '[03:09.24]无法忘记的人\n' +
                        '[03:12.36]\n' +
                        '[03:12.36]Oh oh oh oh oh…\n' +
                        '[03:17.85]\n' +
                        '[03:17.85]I love you more than you\'ll ever know\n' +
                        '[03:20.60]\n' +
                        '[03:20.60]\n' +
                        '[03:46.02]吹いていった風の後を\n' +
                        '[03:46.02]可曾记得   追寻着流风的\n' +
                        '[03:50.23]那个明媚晃眼的午后\n' +
                        '[03:50.23]追いかけた　眩しい午後\n' +
                        '[03:55.41]\n' +
                        '[03:55.41]\n',
                },
                // {
                //     title: "",
                //     artist: "",
                //     src: '',
                //     pic: '',
                //     lrc: '',
                // },
            ],
            images: [
                'url(musicsboximages/musicboximg1.png)',
                'url(musicsboximages/musicboximg2.png)',
                'url(musicsboximages/musicboximg3.jpg)',
            ],
        };
    },
    watch:{
        drawer1(newVal){
            if (newVal) {
                this.$nextTick(() => {
                    this.setRandomBackgroundImage(1);
                });
            }
        },
        drawer2(newVal){
            if (newVal) {
                this.$nextTick(() => {
                    this.setRandomBackgroundImage(2);
                });
            }
        },
    },
    methods: {
        setRandomBackgroundImage(drawerNumber) {
            const randomIndex = Math.floor(Math.random() * this.images.length);
            let drawerRef = drawerNumber === 1 ? this.$refs.aplayer1 : this.$refs.aplayer2;
            if (drawerRef && drawerRef.$el) {
                const drawerElement = drawerRef.$el.closest('.el-drawer');
                if (drawerElement) {
                    drawerElement.style.backgroundImage = this.images[randomIndex];
                }
            }
        },
    }
}
</script>

<style scoped>
/deep/ .el-drawer.ltr {
    border-radius: 0 15px 0 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.aplayer {
    border-radius: 0 15px 0 0;
    background-color: rgba(255,255,255,0.1);
}

/deep/ .aplayer-lrc:after {
    display: none;
}

/deep/ .aplayer-lrc:before {
    display: none
}

.musicbox {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('@/assets/music.png');
    position: fixed;
    z-index: 1000;
    bottom: 30px;
}
</style>


<!--<template>-->
<!--    <div>-->
<!--        <div class="musicbox" @click="drawer = true">-->

<!--        </div>-->
<!--        <el-drawer :visible.sync="drawer" :with-header="false" direction="ltr" size="600px">-->
<!--            <vue-aplayer ref="aplayer"-->
<!--                         :list="musics"-->
<!--                         :listFolded="isvlistFolded"-->
<!--                         :music="musics[0]"-->
<!--                         :mutex="true"-->
<!--                         :showLrc="true"-->
<!--                         fixed-->
<!--            />-->
<!--        </el-drawer>-->
<!--    </div>-->

<!--</template>-->

<!--<script>-->
<!--import vueAplayer from "vue-aplayer";-->

<!--export default {-->
<!--    // eslint-disable-next-line vue/multi-word-component-names-->
<!--    name: "musicbox",-->
<!--    components: {-->
<!--        vueAplayer-->
<!--    },-->
<!--    data() {-->
<!--        return {-->
<!--            drawer: false,-->
<!--            isvlistFolded: true,-->
<!--            musics: [-->
<!--                {-->
<!--                    title: "",-->
<!--                    artist: "",-->
<!--                    src: '',-->
<!--                    pic: '',-->
<!--                    lrc: '',-->
<!--                },-->
<!--            ]-->
<!--        }-->
<!--    },-->
<!--    watch: {-->
<!--        drawer(newVal) {-->
<!--            if (newVal) {-->
<!--                this.$nextTick(() => {-->
<!--                    const listToggle = this.$refs.aplayer.$el.querySelector('.aplayer-list-toggle');-->
<!--                    listToggle.addEventListener('click', this.toggleList);-->
<!--                });-->
<!--            }-->
<!--        }-->
<!--    },-->
<!--    methods: {-->
<!--        toggleList() {-->
<!--            const list = this.$refs.aplayer.$el.querySelector('.aplayer-list');-->
<!--            if (list.classList.contains('aplayer-list-hide')) {-->
<!--                list.classList.remove('aplayer-list-hide');-->
<!--                list.classList.add('aplayer-list-hide-up');-->
<!--            } else {-->
<!--                list.classList.remove('aplayer-list-hide-up');-->
<!--                list.classList.add('aplayer-list-hide');-->
<!--            }-->
<!--        }-->
<!--    },-->


<!--}-->
<!--</script>-->

<!--<style scoped>-->
<!--/deep/ .el-drawer.ltr {-->
<!--    height: auto;-->
<!--    border-radius: 0 15px 0 0;-->
<!--    background-size: cover;-->
<!--    background-position: center;-->
<!--    background-repeat: no-repeat;-->
<!--    background-color: #FFF0F5;-->
<!--}-->

<!--.aplayer {-->
<!--    position: absolute;-->
<!--    bottom: 0;-->
<!--}-->

<!--.aplayer-list {-->
<!--    top: auto !important;-->
<!--    bottom: 50px; /* 根据你的需求调整这个值 */-->
<!--}-->

<!--.aplayer-list.aplayer-list-hide {-->
<!--    display: none;-->
<!--}-->

<!--.aplayer-list.aplayer-list-hide-up {-->
<!--    display: block;-->
<!--    opacity: 1;-->
<!--    bottom: calc(100% + 10px); /* 根据你的需求调整这个值 */-->
<!--}-->


<!--.musicbox {-->
<!--    width: 100px;-->
<!--    height: 100px;-->
<!--    background-color: #5b6c7d;-->
<!--    position: fixed;-->
<!--    z-index: 1000;-->
<!--    bottom: 0;-->
<!--}-->
<!--</style>-->