<template>
    <div>
        <!--        <div class="box hidden-sm-and-down">-->
        <!--            <div v-for="product in tableData" :key="product.id" class="box-b">-->
        <!--                <a :href="product.url" target="_blank">-->
        <!--                    <img :src="product.imgurl || defaultImgUrl" :title="product.name">-->
        <!--                    <p>{{ product.name }}</p>-->
        <!--                </a>-->
        <!--            </div>-->
        <!--        </div>-->
        <CarouselAnime/>
        <div class="container hidden-sm-and-down">
            <div v-for="product in tableData" :key="product.id" class="box">
                <div class="img-box">
                    <img :src="product.imgurl || defaultImgUrl" :title="product.name" alt="">
                </div>
                <div class="text-box">
                    <a :href="product.url" target="_blank">
                        <div>
                            <h2>{{ product.name }}</h2>
                        </div>
                    </a>
                </div>
            </div>
        </div>


        <el-dialog
                :close-on-click-modal="false"
                :modal="false"
                :show-close="false"
                :visible.sync="dialogVisible"
                :width="dialogWidth"
                title="声明"
        >
            <span>本页面资源均来自网络，均未提供资源上传、存储服务.如侵犯贵方权益请联系管理员删除。如果 <span
                    style="color:red;">链接失效了</span> 记得联系管理员替换链接。</span>
            <span slot="footer" class="dialog-footer">
        <el-button style="width: 100px;height: 30px;" type="primary" @click="handleConfirm">确认</el-button>
      </span>
        </el-dialog>


        <div class="min-box hidden-md-and-up">
            <div v-for="product in tableData" :key="product.id" class="min-box-b">
                <a :href="product.url" target="_blank">
                    <img :src="product.imgurl || defaultImgUrl" :title="product.name" alt="">
                    <p>{{ product.name }}</p>
                </a>
            </div>
        </div>

    </div>


</template>

<script>
import api from "@/API/UPAPI/Animation";
import CarouselAnime from "@/components/CarouselAnime.vue";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Anime",
    components: {CarouselAnime},
    data() {
        return {
            dialogWidth: '',
            dialogVisible: false,
            tableData: [],
            defaultImgUrl: '/images/sb.jpg'
        };
    },
    mounted() {
        this.checkDialogStatus();
        this.GetAnimation();
        this.setDialogWidth(); // 页面加载时设置对话框宽度
        window.addEventListener('resize', this.setDialogWidth); // 监听窗口大小变化
    },

    destroyed() {
        window.removeEventListener('resize', this.setDialogWidth); // 组件销毁时移除事件监听
    },

    methods: {

        setDialogWidth() {
            if (window.innerWidth <= 992) { // 假设小屏幕设备宽度小于等于768px
                this.dialogWidth = '300px';
            } else {
                this.dialogWidth = ''; // 如果不是小屏幕，不设置固定宽度，保持默认
            }
        },


        async GetAnimation() {
            try {
                const productList = await api.AnimationData();
                this.tableData = productList.data
            } catch (error) {
                console.error("获取数据失败", error);
            }
        },


        checkDialogStatus() {
            const now = new Date().getTime();
            const lastConfirmedTime = localStorage.getItem('dialogConfirmedTime');
            if (!lastConfirmedTime || now - lastConfirmedTime > 24 * 60 * 60 * 1000) {
                this.dialogVisible = true;
            }
        },
        handleConfirm() {
            const now = new Date().getTime();
            localStorage.setItem('dialogConfirmedTime', now);
            this.dialogVisible = false;
        }
    }
}
</script>

<style scoped>
* {
    padding: 0;
    margin: 0;
    text-decoration: none;
}

/*.box {*/
/*    margin: 20px auto;*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    gap: 10px;*/
/*    justify-content: flex-start;*/
/*}*/

/*.box-b {*/
/*    width: 185px;*/
/*    height: 280px;*/
/*    border-radius: 10px 10px 0 0;*/
/*    overflow: hidden;*/
/*}*/
/*.box-b a {*/
/*    color: black;*/
/*}*/
/*.box-b img {*/
/*    width: 185px;*/
/*    height: 259px;*/
/*    border-radius: 10px;*/
/*}*/
/*.box-b img:hover {*/
/*    box-shadow: 0 12px 15px -9px black; !*盒子阴影*!*/
/*    transition: all 0.5s; !*持续时间*!*/
/*}*/

/*.box-b:hover a {*/
/*    color: #48D1CC;*/
/*}*/

.box-b p {
    text-align: center;
    width: 185px;
    height: 21px;
    line-height: 20px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.min-box {
    max-width: 992px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
}

.min-box-b {
    width: 165px;
    height: 260px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}


.min-box-b a {
    color: black;
}

.min-box-b img {
    width: 165px;
    height: 229px;
    border-radius: 10px;
}

.min-box-b p {
    text-align: center;
    width: 165px;
    height: 21px;
    line-height: 20px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.container .box {
    position: relative;
    width: 185px;
    height: 280px;
    overflow: hidden;
    transition: 0.5s;
    margin: 25px;
}

.container .box:hover {
    transform: scale(1.1);
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.container .box .img-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.container .box .img-box::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #222, transparent);
    z-index: 1;
    opacity: 0;
    transition: 0.5s;
}

.container .box:hover .img-box::before {
    opacity: 1;
}

.container .box .img-box img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container .box .text-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #fff;
    z-index: 2;
}

.container .box .text-box h2 {
    font-size: 13px;
    margin-bottom: 10px;
    transform: translateY(100px);
    color: white;
    transition: 0.5s;
}

.container .box:hover .text-box h2 {
    transform: translateY(0);
    transition-delay: 0.3s;
}

.container .box .text-box p {
    font-size: 13px;
    line-height: 20px;
    transform: translateY(200px);
    transition: 0.5s;
}

.container .box:hover .text-box p {
    transform: translateY(0);
    transition-delay: 0.4s;
}

</style>