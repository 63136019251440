<template>
    <div>
        <!-- 搜索框 -->
        <el-input v-model="searchName" placeholder="请输入动漫名搜索" style="margin-bottom: 20px; width: 300px;"></el-input>
        <el-button type="primary" @click="handleAdd">新增动漫</el-button>

        <!-- 表格 -->
        <el-table :data="filteredData" border max-height="1000" style="width: 100%">
            <el-table-column label="id" prop="id" width="100"></el-table-column>
            <el-table-column label="动漫名称" prop="name" width="180"></el-table-column>
            <el-table-column label="动漫封面图地址" prop="imgurl" width="180"></el-table-column>
            <el-table-column label="动漫网址" prop="url"></el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                    <el-button size="small" type="text" @click="handleClick(scope.row)">编辑</el-button>
                    <el-button size="small" type="text" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 编辑/新增表单 -->
        <el-dialog :visible.sync="dialogVisible" title="编辑动漫">
            <el-form ref="form" :model="form" label-width="80px" label-position="top">
                <el-form-item label="动漫名称:" required>
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="动漫封面地址:">
                    <el-input v-model="form.imgurl"></el-input>
                </el-form-item>
                <el-form-item label="动漫网址:" required>
                    <el-input v-model="form.url"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">提交</el-button>
                    <el-button @click="dialogVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/API/UPAPI/Animation";
import axios from "axios";
import {MessageBox} from "element-ui";
const baseUrl = `http://${window.location.hostname}:9090`;
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "upload",
    data() {
        return {
            tableData: [],
            searchName: '',  // 动漫名
            dialogVisible: false,
            form: {
                id: null,
                name: '',
                imgurl: '',
                url: ''
            },
            isEdit: false,
            editRow: null  // 存储当前编辑行数据
        };
    },
    created() {

    },
    async mounted() {
        try {
            const productList = await api.AnimationData();
            this.tableData = productList.data
        } catch (error) {
            console.error("获取数据失败", error);
        }
    },
    computed: {
        filteredData() {
            if (this.searchName) {
                return this.tableData.filter(item => item.name && item.name.includes(this.searchName));
            }
            return this.tableData;
        }
    },

    methods: {
        handleAdd() {
            this.isEdit = false;
            this.form = { id: null, name: '', imgurl: '', url: '' };
            this.dialogVisible = true;
        },
        handleClick(row) {
            this.isEdit = true;
            this.editRow = row;  // 存储当前编辑的行数据
            this.form = { ...row };  // 填充表单数据
            this.dialogVisible = true;
        },
        async handleDelete(row) {
            try {
                await MessageBox.confirm('确定要删除？Σ(っ°Д°;)っ', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                const response = await axios.post(`${baseUrl}/DeleteAnimation`, { id: row.id });
                if (response.data.message === 'ok') {
                    this.tableData = this.tableData.filter(item => item.id !== row.id);
                } else {
                    console.error("删除失败");
                }
            } catch (error) {
                console.error("删除失败", error);
            }
        },
        async onSubmit() {
            // 验证动漫名称、封面地址和网址是否为空
            if (!this.form.name || !this.form.url) {
                MessageBox.confirm('动漫名称或网址不能为空', '提示', {
                    confirmButtonText: '确定',
                }).catch(() => {
                    // 确认对话框点击取消的处理逻辑
                });
                return;
            }
            if (this.isEdit) {
                // 编辑逻辑
                try {
                    const response = await axios.post(`${baseUrl}/DAnimation`, { ...this.form });
                    if (response.data.message === 'ok') {
                        const index = this.tableData.findIndex(item => item.id === this.editRow.id);
                        if (index !== -1) {
                            this.$set(this.tableData, index, { ...this.form });
                        }
                        this.dialogVisible = false;  // 只有在成功时关闭对话框
                    } else {
                        console.error("编辑失败");
                    }
                } catch (error) {
                    console.error("编辑失败", error);
                }
            } else {
                // 新增逻辑
                try {
                    const response = await axios.post(`${baseUrl}/UpAnimation`, { ...this.form });
                    if (response.data.message === 'ok' && response.data.id) {
                        this.form.id = response.data.id; // 获取后端返回的新建记录的ID
                        this.tableData.push({ ...this.form });
                        this.dialogVisible = false;  // 只有在成功时关闭对话框
                    } else {
                        console.error("新增失败");
                    }
                } catch (error) {
                    console.error("新增失败", error);
                }
            }
        }
    }
}
</script>

<style scoped>
/deep/.el-form-item__label {
    position: absolute;
    top: -20px; /* 调整标签位置 */
    left: 0;
}
/deep/.el-form-item {
    position: relative;
    padding-top: 20px; /* 增加上方填充 */
}
</style>