<template>
    <div>
        <div class="N"></div>
        <vue-particles
                :clickEffect="true"
                :hoverEffect="true"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :linesWidth="1"
                :moveSpeed="3"
                :particleOpacity="0.7"
                :particleSize="6"
                :particlesNumber="60"
                class="login-background"
                clickMode="push"
                color="#409EFF"
                hoverMode="grab"
                linesColor="#409EFF"
                shapeType="circle">
        </vue-particles>
    </div>
</template>

<script>
export default {
    name: "GmaeBJ"
}
</script>

<style scoped>
.login-background {
    /*background: linear-gradient(-180deg, #dceaf2 0%, #ffffff 100%);*/
    /*background: linear-gradient(-180deg, #e2d1e1 0%, #d7d0d9 100%);*/
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.N {
    position: fixed;
    background: linear-gradient(-180deg, #e2d1e1 0%, #d7d0d9 100%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
}
</style>