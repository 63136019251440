<template>
    <div class="btn">
        <strong>{{ currentTime }}</strong>
        <div id="container-stars">
            <div id="stars"></div>
        </div>

        <div id="glow">
            <div class="circle"></div>
            <div class="circle"></div>
        </div>
    </div>



<!--    <div class="time">-->
<!--        <p>{{ currentTime }}</p>-->
<!--    </div>-->
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Weather",
    data() {
        return {
            currentTime: null
        };
    },
    created() {
        this.getCurrentTime();
        this.timer = setInterval(() => {
            this.getCurrentTime();
        }, 1000); // 每秒更新一次时间
    },
    beforeDestroy() {
        clearInterval(this.timer); // 组件销毁前清除定时器
    },
    methods: {
        getCurrentTime() {
            const date = new Date();
            this.currentTime = date.toLocaleTimeString();
        }
    }
};
</script>

<style scoped>
/*.time {*/
/*    !*width: 180px;*!*/
/*    !*padding: 10px;*!*/
/*    !*border-radius: 5px;*!*/
/*    !*background: aliceblue;*!*/
/*    !*margin: auto;*!*/
/*    !*overflow: hidden;*!*/
/*    margin: 0;*/
/*    box-shadow: black 1px 1px;*/
/*    padding: 0 5px 5px 0;*/
/*}*/

/*.time p {*/
/*    margin: 0;*/
/*    background-color: pink;*/
/*    padding: 20px 10px;*/

/*}*/

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 3rem;
    background-size: 300% 300%;
    backdrop-filter: blur(1rem);
    border-radius: 5rem;
    transition: 0.5s;
    animation: gradient_301 5s ease infinite;
    border: double 4px transparent;
    background-image: linear-gradient(#212121, #212121),  linear-gradient(137.48deg, #ffdb3b 10%,#FE53BB 45%, #8F51EA 67%, #0044ff 87%);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

#container-stars {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: 0.5s;
    backdrop-filter: blur(1rem);
    border-radius: 5rem;
}

strong {
    z-index: 2;
    font-family: 'Avalors Personal Use';
    font-size: 12px;
    letter-spacing: 5px;
    color: #FFFFFF;
    text-shadow: 0 0 4px white;
}

#glow {
    position: absolute;
    display: flex;
    width: 12rem;
}

.circle {
    width: 100%;
    height: 30px;
    filter: blur(2rem);
    animation: pulse_3011 4s infinite;
    z-index: -1;
}

.circle:nth-of-type(1) {
    background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
    background: rgba(142, 81, 234, 0.704);
}

.btn:hover #container-stars {
    z-index: 1;
    background-color: #212121;
}

.btn:hover {
    transform: scale(1.1)
}

.btn:active {
    border: double 4px #FE53BB;
    background-origin: border-box;
    background-clip: content-box, border-box;
    animation: none;
}

.btn:active .circle {
    background: #FE53BB;
}

#stars {
    position: relative;
    background: transparent;
    width: 200rem;
    height: 200rem;
}

#stars::after {
    content: "";
    position: absolute;
    top: -10rem;
    left: -100rem;
    width: 100%;
    height: 100%;
    animation: animStarRotate 90s linear infinite;
}

#stars::after {
    background-image: radial-gradient(#ffffff 1px, transparent 1%);
    background-size: 50px 50px;
}

#stars::before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 170%;
    height: 500%;
    animation: animStar 60s linear infinite;
}

#stars::before {
    background-image: radial-gradient(#ffffff 1px, transparent 1%);
    background-size: 50px 50px;
    opacity: 0.5;
}

@keyframes animStar {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-135rem);
    }
}

@keyframes animStarRotate {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0);
    }
}

@keyframes gradient_301 {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes pulse_3011 {
    0% {
        transform: scale(0.75);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.75);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}













</style>