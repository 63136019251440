<template>
    <div class="bg"></div>
</template>

<script>
export default {
    name: "GameBg",
    data() {
        return {
            images: [
                'url(/BGimages/log1.png)',
                'url(/BGimages/log2.jpeg)',
                'url(/BGimages/log3.jpg)',
                'url(/BGimages/log4.jpg)',
                'url(/BGimages/log5.jpg)',
                'url(/BGimages/log6.jpg)',
            ]
        };
    },
    mounted() {
        this.changeBackground(); // 初始化时调用一次
        setInterval(this.changeBackground, 60000); // 每隔1分钟调用一次
    },
    methods: {
        changeBackground() {
            const randomIndex = Math.floor(Math.random() * this.images.length);
            const randomImage = this.images[randomIndex];
            console.log('Changing background to:', randomImage); // 调试输出
            this.setBackgroundImage(randomImage);
        },
        setBackgroundImage(image) {
            const bgElement = this.$el.closest('.bg');
            if (bgElement) {
                bgElement.style.backgroundImage = image;
            }
        }
    }
};
</script>

<style>
.bg {
    position: fixed;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -90;
}
</style>