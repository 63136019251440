<template>
<div>
<!--    <meteor-module/>-->
    <div class="bg"></div>
</div>
</template>

<script>
export default {
    name: "BgTwo"
}
</script>

<style scoped>
.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -90;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../assets/bj1.png);
    opacity: 0.8;
}
</style>