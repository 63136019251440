<template>
    <button class="bin-button" @click="gobtn(product.id)">
        <svg class="bin-top" fill="none" viewBox="0 0 39 7" xmlns="http://www.w3.org/2000/svg">
            <line stroke="white" stroke-width="4" x2="39" y1="5" y2="5"></line>
            <line stroke="white" stroke-width="3" x1="12" x2="26.0357" y1="1.5" y2="1.5"></line>
        </svg>
        <svg class="bin-bottom" fill="none" viewBox="0 0 33 39" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_8_19" fill="white">
                <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
            </mask>
            <path d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z" fill="white" mask="url(#path-1-inside-1_8_19)"></path>
            <path d="M12 6L12 29" stroke="white" stroke-width="4"></path>
            <path d="M21 6V29" stroke="white" stroke-width="4"></path>
        </svg>
    </button>

</template>

<script>
import axios from "axios";
import { MessageBox,Message } from 'element-ui';
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Delete",
    props: {
        product: Object // 声明接收名为 product 的 prop
    },
    methods: {
        gobtn(id){
            MessageBox.confirm('确定要删除？Σ(っ°Д°;)っ', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const baseUrl = `http://${window.location.hostname}:9090`;
                axios.delete(`${baseUrl}/DeletePost?id=${id}`)
                Message({
                    type: 'success',
                    message: '删除成功! (꒪Д꒪)ノ '
                });
                location.reload();
            }).catch(() => {
                Message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }
    }
}
</script>

<style scoped>
.bin-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    background-color: transparent;
    cursor: pointer;
    border: none;
    transition-duration: 0.3s;
}

.bin-bottom {
    width: 15px;
}

.bin-top {
    width: 17px;
    transform-origin: right;
    transition-duration: 0.3s;
}

.bin-button:hover .bin-top {
    transform: rotate(45deg);
}

.bin-button:hover .bin-top, .bin-button:hover .bin-bottom {
    background-color: rgb(255, 0, 0);
}

.bin-button:active {
    transform: scale(0.9);
}

</style>