<template>
    <div>
        <el-input v-model="searchId" placeholder="请输入ID搜索" style="margin-bottom: 20px; width: 300px;"></el-input>
        <el-table :data="filteredData" border max-height="1000" style="width: 100%">
            <el-table-column label="id" prop="id" width="180"></el-table-column>
            <el-table-column label="姓名" prop="user_name" width="180"></el-table-column>
            <el-table-column label="uid" prop="uid" width="180"></el-table-column>
            <el-table-column label="个性签名" prop="sign" width="200"></el-table-column>
            <el-table-column label="人员属性">
                <template slot-scope="scope">
                    <span v-if="scope.row.permissions === 2" class="namecolor">创世神</span>
                    <span v-else-if="scope.row.permissions === 1">管理员</span>
                    <span v-else>普通用户</span>
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="200">
                <template slot-scope="scope">
                    <div v-if="currentUserPermissions === 1">
                        <el-button :disabled="scope.row.permissions === 1 || scope.row.permissions === 2" size="small"
                                   type="primary"
                                   @click="UpAdmin(scope.row.id)">设为管理
                        </el-button>
                        <el-button :disabled="scope.row.permissions === 2" size="small" type="danger"
                                   @click="deleteUser">小黑屋
                        </el-button>
                    </div>
                    <div v-else-if="currentUserPermissions === 2">
                        <el-button v-if="scope.row.permissions === 1" size="small" type="warning"
                                   @click="delAdmin(scope.row.id)">降级管理
                        </el-button>
                        <el-button v-else-if="scope.row.permissions === 0" size="small" type="primary"
                                   @click="UpAdmin(scope.row.id)">设为管理
                        </el-button>
                        <el-button :disabled="scope.row.permissions === 2" size="small" type="danger"
                                   @click="deleteUser">小黑屋
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>


        <el-dialog
                :close-on-click-modal="false"
                :show-close="false"
                :visible.sync="dialogVisible"
                title="说明"
        >
            <span>功能尚未开通</span>
            <span slot="footer" class="dialog-footer">
        <el-button style="width: 100px;height: 30px;line-height:5px;" type="primary"
                   @click="handleConfirm">确认</el-button>
      </span>
        </el-dialog>

    </div>
</template>

<script>
import api from '../API/UPAPI/upapi';
import axios from "axios";

const baseUrl = `http://${window.location.hostname}:9090`;
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "administrator",
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            searchId: '', // 用于绑定搜索框的输入
            currentUserPermissions: '',
        }
    },
    created() {
        this.getpermissions();
    },
    async mounted() {
        try {
            const productList = await api.adminData();
            this.tableData = productList.data
        } catch (error) {
            console.error("获取数据失败", error);
        }
    },
    computed: {
        filteredData() {
            if (this.searchId) {
                return this.tableData.filter(item => String(item.id).includes(this.searchId));
            }
            return this.tableData;
        }
    },
    methods: {
        // 降级
        async delAdmin(id) {
            try {
                await axios.post(`${baseUrl}/DelAdmin`, {id});
                // 更新本地数据，假设后端会返回更新后的用户数据
                this.tableData = this.tableData.map(item => {
                    if (item.id === id) {
                        return {...item, permissions: 0}; // 假设1是管理员权限
                    }
                    return item;
                });
            } catch (error) {
                console.error("降级失败", error);
            }
        },
        // 升级
        async UpAdmin(id) {
            try {
                await axios.post(`${baseUrl}/UpAdmin`, {id});
                // 更新本地数据，假设后端会返回更新后的用户数据
                this.tableData = this.tableData.map(item => {
                    if (item.id === id) {
                        return {...item, permissions: 1}; // 假设1是管理员权限
                    }
                    return item;
                });
            } catch (error) {
                console.error("升级失败", error);
            }
        },
        deleteUser() {
            this.dialogVisible = true;
        },
        async getpermissions() {
            try{
                const id = localStorage.getItem('userId');
                const response = await axios.post(`${baseUrl}/min`, {
                    id
                });
               this.currentUserPermissions = response.data.permissions
                console.log(this.currentUserPermissions)
            }catch (error){
                console.error("获取失败", error);
            }
        },
        handleConfirm() {
            this.dialogVisible = false;
        },
    }
}
</script>

<style scoped>

/deep/ .cell {
    max-width: 200px;
    max-height: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.namecolor {
    background-image: linear-gradient(-135deg, #418ae0, #56a0d8, #dc8bc3, #56a0d8, #418ae0, #56a0d8, #dc8bc3, #56a0d8, #418ae0);
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    -webkit-background-clip: text;
    background-size: 200% 100%;
    animation: masked-animation 3s infinite linear;
}

@keyframes masked-animation {
    0% {
        background-position: 0 -100%;
    }
    100% {
        background-position: -100% 0;
    }
}

</style>