import Vue from 'vue'
import {
    Button,
    Submenu,
    Menu,
    MenuItem,
    Header,
    Container,
    Backtop,
    Aside,
    Main,
    Footer,
    Col,
    Carousel,
    CarouselItem,
    Select,
    MenuItemGroup,
    DatePicker,
    Dialog,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Table,
    TableColumn,
    ButtonGroup,
    Form,
    FormItem,
    Input,
    Popconfirm,
    Row,
    Icon,
    Empty,
    Avatar,
    Option,
    Popover,
    Tooltip,
    Pagination,
    Skeleton,
    SkeletonItem,
    Drawer,
} from 'element-ui';

Vue.use(Button)
Vue.use(Submenu)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Header)
Vue.use(Container)
Vue.use(Backtop)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Col)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Select)
Vue.use(MenuItemGroup)
Vue.use(DatePicker)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(ButtonGroup)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Popconfirm)
Vue.use(Row)
Vue.use(Icon)
Vue.use(Empty)
Vue.use(Avatar)
Vue.use(Option)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Drawer)
