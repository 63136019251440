import { render, staticRenderFns } from "./GameSpace.vue?vue&type=template&id=2db7de12&scoped=true&"
import script from "./GameSpace.vue?vue&type=script&lang=js&"
export * from "./GameSpace.vue?vue&type=script&lang=js&"
import style0 from "./GameSpace.vue?vue&type=style&index=0&id=2db7de12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db7de12",
  null
  
)

export default component.exports