<template>
    <div>
        <LoadingTwo v-if="showLoading"/>
        <GameBg/>
        <div class="hidden-sm-and-down">
<!--            <GmaeBJ/>-->
            <el-row :gutter="20">
                <el-col :span="4" style="width: 200px;margin-right: 20px;">
                    <div class="col-l">
                        <button class="button" style="vertical-align:middle" @click="Game"><span>小游戏</span></button>
                        <button class="button" style="vertical-align:middle" @click="Anime"><span>动漫</span></button>
                        <button class="button" style="vertical-align:middle"><span>更多敬请期待</span></button>
                    </div>
                </el-col>
                <el-col :span="18">
                    <router-view></router-view>
                </el-col>

            </el-row>
        </div>

        <div class="hidden-md-and-up">
            <div style="max-width: 992px; display: flex;justify-content: left;gap: 20px">
                <button class="button" @click="Game"><span>小游戏</span></button>
                <button class="button" @click="Anime"><span>动漫</span></button>
                <button class="button"><span>更多敬请期待</span></button>
            </div>
            <router-view></router-view>
        </div>

    </div>


</template>

<script>
import LoadingTwo from "@/components/LoadingTwo.vue";
import GameBg from "@/components/GameBg.vue";

export default {
    name: "GameSpace",
    components: {GameBg, LoadingTwo},
    data() {
        return {
            showLoading: true,  // 控制 LoadingTwo 显示的状态
        };
    },
    methods: {
        Anime() {
            if (this.$route.path !== '/GameSpace/Anime') {
                this.$router.push('/GameSpace/Anime');
            }
        },
        Game() {
            if (this.$route.path !== '/GameSpace/Game') {
                this.$router.push('/GameSpace/Game');
            }
        },
    },
    created() {
        document.title = "异世界";
    },
    mounted() {
        setTimeout(() => {
            this.showLoading = false;
        }, 4500);
    },
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;

}

.col-l {
    width: 200px;
    height: auto;
    min-height: 300px;
    border-radius: 5px;
    padding: 10px 0;
    background-color: rgba(230, 230, 250, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*按钮*/
.button {
    display: inline-block;
    border-radius: 7px;
    border: none;
    background: #87CEFA;
    color: white;
    font-family: inherit;
    text-align: center;
    font-size: 13px;
    width: 170px;
    padding: 1em;
    margin: 10px 0;
    transition: all 0.4s;
    cursor: pointer;
}

.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.button span:after {
    content: 'GO';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.7s;
}

.button:hover span {
    padding-right: 3.55em;
}

.button:hover span:after {
    opacity: 4;
    right: 0;
}

.button:hover {
    background-color: #00BFFF;
}

/*按钮*/
</style>