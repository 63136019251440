<template>
<div>
    <el-row :gutter="20">
        <el-col :span="4" style="width: 200px;margin-right: 20px;">
            <div class="col-l">
                <button class="button" style="vertical-align:middle" @click="administrator"><span>设为管理员</span></button>
                <button class="button" style="vertical-align:middle" @click="upload"><span>上传动漫网址</span></button>
                <button class="button" style="vertical-align:middle"><span>更多敬请期待</span></button>
            </div>
        </el-col>
        <el-col :span="18">
            <router-view></router-view>
        </el-col>

    </el-row>
</div>
</template>

<script>
export default {
    name: "UP",

    methods:{
        administrator() {
            if (this.$route.path !== '/UP/administrator') {
                this.$router.push('/UP/administrator');
            }
        },
        upload() {
            if (this.$route.path !== '/UP/upload') {
                this.$router.push('/UP/upload');
            }
        },
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;

}

.col-l {
    width: 200px;
    height: auto;
    min-height: 300px;
    border-radius: 5px;
    padding: 10px 0;
    background-color: rgba(230, 230, 250, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
}
/*按钮*/
.button {
    display: inline-block;
    border-radius: 7px;
    border: none;
    background: #87CEFA;
    color: white;
    font-family: inherit;
    text-align: center;
    font-size: 13px;
    width: 170px;
    padding: 1em;
    margin: 10px 0;
    transition: all 0.4s;
    cursor: pointer;
}

.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.button span:after {
    content: 'GO';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.7s;
}

.button:hover span {
    padding-right: 3.55em;
}

.button:hover span:after {
    opacity: 4;
    right: 0;
}

.button:hover {
    background-color: #00BFFF;
}

/*按钮*/
</style>