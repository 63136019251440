<template>
    <div>
        <el-container class="hidden-sm-and-down">
            <el-aside width="250px">
                <div class="top"  v-for="top in userData" :key="top.id">
                    <div class="tx">
                        <img :src="top.img_data" alt="Profile">
                    </div>
                    <div class="left">
                        <div class="name">
                            姓名：{{ top.username }}
                        </div>
                        <div class="gender">
                             性别：{{ top.gender }}
                        </div>
                        <div class="age">
                            年龄：{{top.age}}
                        </div>
                        <div class="sign">
                            签名：{{ top.sign }}
                        </div>
                    </div>
                </div>
            </el-aside>

            <el-main style="margin-top: 0">
                <p>修改资料</p>

                <div>
                    <el-form ref="ReviseFormRef" :label-position="labelPosition" :model="ReviseForm" label-width="80px">

                        <el-form-item label="昵称:" prop="username">
                            <el-input v-model="ReviseForm.username" clearable name="username" placeholder="昵称"></el-input>
                        </el-form-item>

                        <el-form-item label="性别:" prop="gender">
                            <el-select v-model="ReviseForm.gender"  name="gender" placeholder="性别"
                                       style="width: 100%;">
                                <el-option label="男" value="男"></el-option>
                                <el-option label="女" value="女"></el-option>
                                <el-option label="保密" value="保密"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="年龄:" prop="age">
                            <el-input v-model="ReviseForm.age" clearable name="age" placeholder="年龄"></el-input>
                        </el-form-item>

                        <el-form-item label="签名:" prop="sign">
                            <el-input v-model="ReviseForm.sign" clearable name="sign" placeholder="签名"></el-input>
                        </el-form-item>

                        <el-form-item label="选择头像:" style="height: 130px;">
                            <input style="float: left; width: 150px;" type="file" @change="toGetImg">
                            <img v-if="valueUrl" :src=valueUrl alt=""
                                 style="width: 100px;height: 100px; border-radius: 100px; float: left">
                        </el-form-item>


                        <el-form-item>
                            <el-button @click="postrevise">确定</el-button>
                        </el-form-item>

                    </el-form>
                </div>

            </el-main>
        </el-container>
        <el-container class="hidden-md-and-up">
            <el-aside width="100px">
                <div class="min-top"  v-for="top in userData" :key="top.id">
                    <div class="min-tx">
                        <img :src="top.img_data" alt="Profile">
                    </div>
                    <div class="min-left">
                        <div class="min-name">
                            姓名：{{ top.username }}
                        </div>
                        <div class="min-gender">
                             性别：{{ top.gender }}
                        </div>
                        <div class="min-age">
                            年龄：{{top.age}}
                        </div>
                        <div class="min-sign">
                            签名：{{ top.sign }}
                        </div>
                    </div>
                </div>
            </el-aside>

            <el-main style="margin-top: 0">
                <p>修改资料</p>

                <div>
                    <el-form ref="ReviseFormRef" :label-position="labelPosition" :model="ReviseForm" label-width="80px">

                        <el-form-item label="昵称:" prop="username">
                            <el-input v-model="ReviseForm.username" clearable name="username" placeholder="昵称"></el-input>
                        </el-form-item>

                        <el-form-item label="性别:" prop="gender">
                            <el-select v-model="ReviseForm.gender"  name="gender" placeholder="性别"
                                       style="width: 100%;">
                                <el-option label="男" value="男"></el-option>
                                <el-option label="女" value="女"></el-option>
                                <el-option label="保密" value="保密"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="年龄:" prop="age">
                            <el-input v-model="ReviseForm.age" clearable name="age" placeholder="年龄"></el-input>
                        </el-form-item>

                        <el-form-item label="签名:" prop="sign">
                            <el-input v-model="ReviseForm.sign" clearable name="sign" placeholder="签名"></el-input>
                        </el-form-item>

                        <el-form-item label="选择头像:" style="height: 130px;">
                            <input style="float: left; width: 150px;" type="file" @change="toGetImg">
                            <img v-if="valueUrl" :src=valueUrl alt=""
                                 style="width: 100px;height: 100px; border-radius: 100px; float: left">
                        </el-form-item>


                        <el-form-item>
                            <el-button @click="postrevise">确定</el-button>
                        </el-form-item>

                    </el-form>
                </div>

            </el-main>
        </el-container>

    </div>
</template>

<script>


import axios from "axios";
import {MessageBox} from "element-ui";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "revise",
    data() {
        return {
            id:'',
            userData:[],
            labelPosition: 'right',
            ReviseForm: {
                username: '',
                gender: '',
                age: '',
                sign: ''
            },
            valueUrl: '', // 用于显示上传的图片
            file: null,
        }
    },
    created() {
        this.id = localStorage.getItem('userId');
        this.getuser(this.id);
        console.log(this.id)
    },

    methods: {
        getuser(id) {
            const baseUrl = `http://${window.location.hostname}:9090`;
            axios.get(`${baseUrl}/postuser?id=${id}`)
                .then(response => {
                    this.userData = response.data;
                    console.log(this.userData)
                }).catch(error => {
                console.error(error);
            })
        },

        async postrevise(){
            this.username = this.ReviseForm.username.trim();
            const username = this.ReviseForm.username.trim();
            const gender = this.ReviseForm.gender.trim();
            const age = this.ReviseForm.age.trim();
            const sign = this.ReviseForm.sign.trim();

            try {
                const formData = new FormData();
                formData.append('username', username);
                formData.append('age', age);
                formData.append('gender', gender);
                formData.append('sign', sign);
                formData.append('file', this.file);

                for (const [key, value] of formData.entries()) {
                    console.log(key, value);
                }
                const baseUrl = `http://${window.location.hostname}:9090`;
                const response = await axios.post(`${baseUrl}/uppostuser?id=${this.id}`, formData);

                if (response.data === 'OK') {
                    MessageBox.alert("修改成功", "提示", {
                        type: "success",
                        duration: 500,
                        callback: () => {
                            localStorage.removeItem('username');
                            localStorage.setItem('username',this.username);
                            location.reload()
                        },
                    });
                }
            } catch (error){
                console.error( error);
            }
        },

        // 头像
        toGetImg(event) {
            this.file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.valueUrl = reader.result;
            };
            reader.readAsDataURL(this.file);
        },
    }
}
</script>

<style scoped>
.el-aside {
    padding: 10px;
    background-color: #D3DCE6;
}

.el-main {
    background-color: #E9EEF3;
}

.top {
    width: 210px;
    padding: 10px;
    background-color: #55acee;
}

.tx {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 150px;
    background-color: #3c3a32;

   img{
    width: 150px;
    height: 150px;
    border-radius: 150px;
   }
}

.left {
    margin-top: 20px;
    text-align: left;
    padding-left: 30px;

}

.sign, .name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.name::before {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 10px;
    background: white;
}

.sign::before {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 10px;
    background: white;
}

.sign {

}


.min-top {
    width: 80px;
}

.min-tx {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border-radius: 60px;
}
.min-tx img{
    width: 60px;
    height: 60px;
    border-radius: 60px;
}

.min-left {
    margin-top: 20px;
    text-align: left;
    font-size: 12px;
}

.min-sign, .min-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.min-name::before {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 10px;
    background: white;
}

.min-sign::before {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 10px;
    background: white;
}

.min-sign {

}

</style>