<template>
    <div >
<!--        <sakura-module/>&lt;!&ndash;        樱花特效&ndash;&gt;-->
        <div class="bg"></div>

    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Bg",
    mounted() {
        this.updateBackgroundImage();
    },
    methods: {
        updateBackgroundImage() {
            const today = new Date();
            const month = today.getMonth() + 1; // getMonth 返回的月份是从0开始的，+1 修正
            const date = today.getDate();
            const specialDates = {
                '10-1': {
                    backgroundSize: 'cover',
                    backgroundPosition:'50%  30%',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: 'url(BGimages/bj.jpg)'
                },
            };

            const key = `${month}-${date}`;
            const bgElement = this.$el.querySelector('.bg');

            if (bgElement) {
                const styles = specialDates[key] || {
                    backgroundImage: 'linear-gradient(to bottom,#00CED1,#87CEFA,#DDA0DD)'
                };
                Object.assign(bgElement.style, styles);
            }
        }
    }
}
</script>

<style scoped>
.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -90;
    opacity: 0.9;
}
</style>