<template>
    <el-carousel :interval="4000" type="card" height="200px" class="imgrow-l hidden-sm-and-down">
        <el-carousel-item v-for="product in randomProducts" :key="product.id" class="carousel-item">
            <a :href="product.url" target="_blank">
                <img :src="product.imgurl" alt="" class="carousel-img">
                <h4>{{ product.name }}</h4>
            </a>
        </el-carousel-item>
    </el-carousel>
</template>

<script>
import api from "@/API/UPAPI/Animation";

export default {
    name: "CarouselAnime",
    data() {
        return {
            tableData: [],        // 存储从API获取的所有数据
            randomProducts: []    // 存储随机选择的四个数据
        };
    },
    created() {
        this.GetAnimation(); // 组件创建时获取数据，只调用一次
    },
    methods: {
        async GetAnimation() {
            try {
                const productList = await api.AnimationData(); // 调用API获取数据
                this.tableData = productList.data; // 将数据存储到tableData中
                this.getRandomProducts(); // 选择随机的四个产品
                console.log(this.randomProducts); // 打印随机选择的数据检查
            } catch (error) {
                console.error("获取数据失败", error); // 错误处理
            }
        },
        getRandomProducts() {
            // 复制tableData并进行打乱（洗牌）
            const shuffled = this.tableData.sort(() => 0.5 - Math.random());
            // 获取前四个
            this.randomProducts = shuffled.slice(0, 4);
        },
    }
}
</script>

<style scoped>

.el-carousel__item:nth-child(2n) {
    background-color:rgba(192,192,192,0.9);
    border-radius: 20px;
}

.el-carousel__item:nth-child(2n+1) {
    border-radius: 20px;
    background-color: rgba(245,245,245,0.9);
}

.carousel-img {
    float: left;
}

h4 {
color: #0d1021;
}
</style>