<template>
    <div class="box">
        <el-form ref="composeRef" :model="composeModel" :rules="rules">

            <el-form-item prop="title" style="margin-bottom: 20px">
                <el-input v-model="composeModel.title" placeholder="标题" maxlength="100" show-word-limit/>
            </el-form-item>

            <el-form-item prop="jubaomatter">
                <el-input v-model="composeModel.jubaomatter" :autosize="{ minRows: 8}" type="textarea" maxlength="1000" show-word-limit
                          placeholder="内容">
                </el-input>
            </el-form-item>


            <el-button circle icon="el-icon-check" style="float: right;margin-top: 10px;" type="success"
                       @click="uppost">

            </el-button>

        </el-form>

    </div>
</template>

<script>

import axios from "axios";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "compose",
    data() {
        return {
            residueNum: 1000,
            composeModel: {
                title: "",
                jubaomatter: ""

            },
            rules: {
                title: [
                    {required: true, message: "标题未写", trigger: "blur"},
                    {min: 2, max: 100, message: "字数少写或已超过", trigger: "blur",},
                ],
                jubaomatter:[
                    {required: true, message: "内容未写", trigger: "blur"},
                    {min: 2, max: 1000, message: "字数少写或已超过", trigger: "blur",},
                ]
            }

        }
    },
    methods: {
        uppost() {
            const jubaomatterLength = this.composeModel.jubaomatter.length;
            const titleLength = this.composeModel.title.length;

            // 检查文本是否为空或超出长度限制
            if (jubaomatterLength === 0 || titleLength === 0) {
                alert("请将内容填写完整")
                return;
            }

            // 检查内容是否超出限制
            if (jubaomatterLength > 1000) {
                alert("帖子内容超过1000字");
                return;
            }

            if (titleLength > 100) {
                alert("帖子标题超过100字");
                return;
            }

            const baseUrl = `http://${window.location.hostname}:9090`;
            this.userId = localStorage.getItem('userId');

            axios.post(`${baseUrl}/uppost`, {
                title: this.composeModel.title,
                content: this.composeModel.jubaomatter,
                topic_id: this.userId,
                created_at: new Date()
            })
                // 1.处理成功响应  2.清空输入框的内容
                .then(response => {
                    console.log(response);
                    this.composeModel.jubaomatter = '';
                    this.composeModel.title = '';
                    setTimeout(() => {
                        this.navigateToAnotherPage();
                    }, 1000);

                }).catch(error => {
                // 处理错误响应
                console.error(error);
            });
        },
        navigateToAnotherPage() {
            this.$router.push("/homepage");
        }
    }
}
</script>

<style scoped>
.box {
    width: 80vw;
    margin: 0 auto;
}
/deep/.el-input__inner{
    padding: 0 60px 0 15px;
}
</style>